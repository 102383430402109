<template>
  <div>
    <b-form
      v-if="!passwordReminderInput"
      @submit.prevent="onSubmit"
    >
      <b-card
        bg-variant="danger text-white"
        v-if="result"
      >
        {{ $t(result.code.replace("/", ".")) }}
      </b-card>

      <p class="text-muted">
        {{ $t("auth.prompt") }}
      </p>
      <b-input-group class="mb-3">
        <b-input-group-prepend>
          <b-input-group-text>
            <font-awesome-icon icon="user" />
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          type="text"
          autofocus
          class="form-control"
          v-model="uid"
          :placeholder="$t('auth.username')"
          autocomplete="username email"
        />
      </b-input-group>
      <b-form-group>
        <b-input-group class="mb-4">
          <b-input-group-prepend>
            <b-input-group-text>
              <font-awesome-icon icon="lock" />
            </b-input-group-text>
          </b-input-group-prepend>
          <b-form-input
            :type="passwordFieldType"
            class="form-control"
            v-model="pwd"
            @keyup.enter="onSubmit"
            :placeholder="$t('auth.password')"
            autocomplete="current-password"
          />
          <b-input-group-append>
            <b-button
              variant="outline-secondary"
              @click="
                () =>
                  (passwordFieldType =
                    passwordFieldType == 'password'
                      ? 'text'
                      : 'password')
              "
            >
              <font-awesome-icon
                color="black"
                :icon="
                  passwordFieldType == 'password'
                    ? 'eye-slash'
                    : 'eye'
                "
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-link @click="passwordReminderInput = true">
          {{ $t("buttons.password_reset") }}
        </b-link>
      </b-form-group>
      <b-row>
        <b-col cols="6">
          <b-button
            variant="primary"
            type="sumbit"
            class="px-4"
            @click="onSubmit()"
          >
            {{ $t("buttons.login") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <PasswordReset
      v-model="uid"
      v-else
    />
  </div>
</template>
<script>
import { auth } from '../firebase'
import PasswordReset from './PasswordReset.vue'
export default {
  name: 'LoginForm',
  components: { PasswordReset },
  mixins: [],
  props: {
  },
  data () {
    return {
      uid: null,
      pwd: null,
      result: null,
      isError: false,
      passwordReminderInput: false,
      passwordFieldType: 'password'
    }
  },
  computed: {},
  watch: {},
  mounted () {

  },
  methods: {
    onSubmit () {
      this.isError = false
      var vm = this
      auth
        .signInWithEmailAndPassword(this.uid, this.pwd)
        .then((userCredential) => {
          // Signed in
          this.$router.push({ name: 'Accomodatie' })
        })
        .catch((error) => {
          vm.result = error
          vm.isError = true
        })
    }
  }

}
</script>
