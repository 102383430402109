<template>
  <b-card :title="$t('navigation.register')">
    <b-overlay :show="processing">
      <b-form-group
        :label="$t('form.name')"
        :description="$t('form.acco_name')"
      >
        <b-input v-model="newUser.name" />
      </b-form-group>

      <b-form-group :label="$t('form.country')">
        <countries v-model="newUser.country" />
      </b-form-group>
      <b-form-group :label="$t('form.email')">
        <b-input
          v-model="newUser.emailadres"
          type="email"
        />
      </b-form-group>

      <b-form-group
        :label="$t('form.reference_code')"
        :description="$t('form.reference_code_explained')"
      >
        <b-input v-model="newUser.reference_code" />
      </b-form-group>

      <b-form-group :label="$t('form.password')">
        <b-input v-model="newUser.password" />
      </b-form-group>
      <b-form-group :label="$t('form.password_confirmation')">
        <b-input
          v-model="newUser.password_confirm"
          :state="newUser.password == newUser.password_confirm"
        />
      </b-form-group>
      <b-alert
        :show="error !== null"
        variant="danger"
      >
        {{ error }}
      </b-alert>
      <b-check v-model="acceptTerms">
        {{ $t('prompts.agree_with_terms') }} <b-link :to="{ name:'Terms'}">
          {{ $t('prompts.terms') }}
        </b-link>
      </b-check>
      <b-button
        variant="ghost"
        class="float-right my-2"
        @click="CreateAccount"
      >
        {{ $t('buttons.next') }} <font-awesome-icon icon="arrow-right" />
      </b-button>
    </b-overlay>
  </b-card>
</template>
<script>
import Countries from '@/components/Countries'
import { auth, db } from '../firebase'

export default {
  components: {
    Countries
  },
  name: 'RegisterView',
  data () {
    return {
      processing: false,
      acceptTerms: false,
      newUser: {
        name: null,
        address: null,
        location: null,
        reference_code: null,
        country: 'NL',
        emailadres: null,
        password: null,
        password_confirm: null
      },
      error: null
    }
  },
  methods: {
    CreateAccount () {
      var user = this.newUser
      var vm = this
      this.error = null
      if (!this.acceptTerms) {
        this.error = this.$t('prompts.alert_agree_terms')
        return
      }
      auth.createUserWithEmailAndPassword(user.emailadres, user.password)
        .then((userCredential) => {
          var newUserId = userCredential.user.uid
          userCredential.user.sendEmailVerification()
          db.collection('profiles').doc(newUserId).set({
            name: user.name,
            reference_code: user.reference_code,
            bookingemail: user.emailadres,
            location: user.location,
            languages: ['EN'],
            country: user.country,
            position: { lat: null, lng: null },
            address: user.address
          }).then(() => {
            vm.$router.push({ name: 'Accomodatie' })
          })
        })
        .catch((error) => {
          vm.error = error
          console.log(error)
        })
        .finally(() => { vm.processing = false })
      console.log('Create Account')
      this.processing = true
      setTimeout(() => { this.processing = false }, 2000)
    }
  }

}
</script>
