<template>
  <b-container>
    <router-view
      :profile="profile"
      :media="media"
    />
  </b-container>
</template>
<script>
export default {
  props: {
    profile: {
      type: Object,
      default: null
    },
    media: {
      type: Array,
      default: () => []
    }
  }
}
</script>
