<template>
  <b-container>
    <b-button
      class="my-3 float-right"
      @click="getPlace"
      variant="primary"
    >
      <font-awesome-icon icon="save" /> {{ $t("buttons.save") }}
    </b-button>
    <b-card v-if="showDetails">
      <b-input-group prepend="Coördinaten">
        <b-input-group-text>{{ value }}</b-input-group-text>
        <b-button @click="getPlace">
          Points
        </b-button>
      </b-input-group>
      <b-card-footer>
        <b-list-group flush>
          <b-list-group-item
            v-for="(r) in result"
            :key="r.place_id"
          >
            {{ r.formatted_address }}<br>
            <small>{{ r.place_id }}</small>
          </b-list-group-item>
        </b-list-group>
      </b-card-footer>
    </b-card>
  </b-container>
</template>
<script>
import Vue from 'vue'

export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  name: 'PlaceId',
  data () {
    return {
      result: null,
      showDetails: false
    }
  },
  methods: {
    getPlace () {
      var vm = this
      Vue.$geocoder.setDefaultMode('lat-lng')
      Vue.$geocoder.send(this.value, (response) => {
        console.log(response)
        vm.result = response.results
        vm.$emit('place_ids', response.results.map((p) => p.place_id))
      })
    }
  }
}
</script>
