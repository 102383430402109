<template>
  <b-input-group>
    <b-form-datepicker
      :locale="$store.getters.dateLocale"
      :date-format-options="format"
      v-model="value.from"
      @input="UpdatePeriod"
      :min="mindate"
      :placeholder="vanlabel"
      :state="$moment(value.from).isAfter(mindate)"
    />
    <b-form-datepicker
      :locale="$store.getters.dateLocale"
      :date-format-options="format"
      v-model="value.till"
      :min="value.from"
      :max="hasMaxdays ? maxEndDate.format('YYYY-MM-DD') : null"
      @input="UpdatePeriod"
      :state="$moment(value.till).isAfter($moment(value.from))"
      :placeholder="totlabel"
    />
  </b-input-group>
</template>
<script>
export default {
  data () {
    return {
      format: { year: 'numeric', month: 'short', day: 'numeric' }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          from: null,
          till: null
        }
      }
    },
    vanlabel: {
      type: String,
      default: 'Van'
    },
    totlabel: {
      type: String,
      default: 'Tot'
    },
    maxdays: {
      type: Number,
      default: null
    },
    mindate: {
      type: Date,
      default: () => new Date()
    }
  },
  computed: {
    IsFromValid: function () {
      return false
    },
    IsTillValid: function () {
      return false
    },
    hasMaxdays: function () {
      return this.maxdays !== null
    },
    maxEndDate: function () {
      return this.$moment(this.value.from).add(this.maxdays, 'days')
    }
  },
  methods: {
    UpdatePeriod () {
      console.log(this.value)
      if (!this.value.till && this.value.from) {
        this.value.till = this.$moment(this.value.from).add(1, 'days').format('YYYY-MM-DD')
      }
      if (this.hasMaxdays) {
        var endDate = this.$moment(this.value.from).add(this.maxdays, 'days')
        // check of eind datum later is
        if (this.$moment(this.value.till).isAfter(endDate)) {
          console.log('update date')
          this.value.till = endDate.format('YYYY-MM-DD')
        }
      }
      this.$emit('update-period', this.value)
    },
    UpdateTill () {
    }
  }
}
</script>
