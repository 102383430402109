<template>
  <div>
    <b-card-group columns>
      <b-form-group
        v-for="(group) in tagOptions"
        :key="$t(`labels.${ group.label.toLowerCase()}`)"
        :label="group.label.toUpperCase()"
      >
        <b-checkbox-group
          :checked="value"
          @input="$emit('input',$event)"
          stacked
          :value="[...value]"
        >
          <b-form-checkbox
            :value="t.value"
            v-for="(t,index) in group.tags"
            :key="index"
          >
            <font-awesome-icon :icon="t.icon" /> {{ t.text }} <small v-if="showOrder">{{ t.order }}</small>
          </b-form-checkbox>
        </b-checkbox-group>
      </b-form-group>
    </b-card-group>
  </div>
</template>
<script>
import { db } from '@/firebase'
import { TagMixin } from '@/mixins/TagMixin'

export default {
  name: 'TagInput',
  components: {},
  mixins: [TagMixin],
  props: {
    settingsDocPath: {
      type: String,
      default: 'settings/default'
    },
    value: {
      type: Array,
      default: () => []
    },
    showOrder: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      settings: null
    }
  },
  computed: {
    tagOptions: function () {
      return this.tagGroups.map((g) => {
        return {
          label: g,
          tags: this.allTags.filter(o => o.category === g && o.active).map(o => {
            return {
              text: this.$t(`tags.${o.tag.toLowerCase()}`),
              value: o.tag,
              icon: o.icon,
              order: o.order
            }
          }).sort(function (a, b) {
            if (a.text < b.text) return -1
            if (a.text > b.text) return 1
            return 0
          })
        }
      })
    },
    allTags: function () {
      if (this.settings === null) return []
      return this.settings.tags
    }
  },
  watch: {},
  firestore: {
    settings: db.doc('settings/default')
  },
  methods: {}

}
</script>
