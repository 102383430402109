<template>
  <b-form v-if="!passwordReminderInput">
    <b-card
      :bg-variant="
        isError ? 'danger text-white' : 'success text-white'
      "
      v-if="result"
    >
      {{ $t(result.code.replace("/", ".")) }}
    </b-card>
    <b-form-group
      class="my-2"
      :label="$t('prompts.email_password_reset')"
    >
      <b-input
        :value="value"
        @input="$emit('input',$event)"
      />
    </b-form-group>
    <b-row>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="px-4"
          @click="onSendPasswordReset"
        >
          {{ $t("buttons.send") }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>
<script>
import { auth } from '../firebase'
export default {
  name: 'PasswordReset',
  components: {},
  mixins: [],
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {},
  watch: {},
  mounted () {

  },
  methods: {
    onSendPasswordReset () {
      var vm = this
      this.isError = false
      auth
        .sendPasswordResetEmail(this.value, {
          url: 'https://booknites.com/login'
        })
        .then((resp) => {
          console.log(resp)
          vm.result = {
            code: 'prompts.password-reset-send',
            message: ''
          }
        })
        .catch((error) => {
          vm.isError = true
          vm.result = error
        })
    }
  }

}
</script>
