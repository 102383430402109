<template>
  <b-input-group>
    <b-input
      :value="text"
      @input="value = $event"
      :placeholder="placeholder"
    />
    <b-input-group-append>
      <b-button
        v-if="text !== value && value !== null"
        @click="updateText"
        variant="primary"
      >
        <font-awesome-icon icon="save" /> opslaan
      </b-button>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
export default {
  name: 'TextEdit',
  data () {
    return {
      value: null
    }
  },
  props: {
    text: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  methods: {
    updateText () {
      this.$emit('save', this.value)
      console.log(this.value)
    }
  }

}
</script>
