<template>
  <b-container>
    <b-row>
      <b-col cols="8">
        <ad
          :offer="offer"
          :language="language"
        />
      </b-col>
      <b-col>
        <b-list-group
          class="my-3"
        >
          <b-list-group-item
            v-for="(item,index) in validation"
            :key="index"
          >
            <font-awesome-icon
              :icon="item.result ? 'check' : 'times'"
              :class="item.result ? 'text-success' : 'text-danger'"
            />
            <b-link @click="() => $emit('goto',item.step)">
              {{ item.label }}
            </b-link>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
    <b-progress
      class="my-3"
      :max="total_items"
      :value="total_completed_items"
      animated
    />
    <b-alert
      variant="warning"
      :show="valid"
    >
      {{ $t('prompts.payment_notification') }}
    </b-alert>

    <b-button-toolbar class="float-right my-4">
      <b-button
        variant="link"
        class="text-danger"
        @click="$emit('cancel')"
      >
        <font-awesome-icon icon="times" /> {{ $t("buttons.cancel_ad") }}
      </b-button>
      <b-button
        variant="primary"
        :disabled="!valid"
        size="lg"
        @click="$emit('publish')"
      >
        {{ $t("buttons.publish_ad")
        }} <font-awesome-icon icon="check-circle" />
      </b-button>
    </b-button-toolbar>
  </b-container>
</template>
<script>
import Ad from '../components/Ad.vue'
export default {
  name: 'OfferPreview',
  components: { Ad },
  computed: {
    valid: function () { return this.total_completed_items === this.total_items },
    validation: function () {
      return [
        {
          label: this.$t('validate.period'),
          result: (this.offer.from !== null) && (this.offer.till !== null),
          step: 'period'
        },
        {
          label: this.$t('validate.rate_discount'),
          result: (this.offer.rate !== null && this.offer.discount !== null),
          step: 'period'
        },
        {
          label: this.$t('validate.photos'),
          result: (this.offer.photos || []).length > 0,
          step: 'media'
        },
        ...this.languages.map((f) => {
          return {
            label: this.$t('validate.title', { lang: f }),
            input: this.offer[f] ? this.offer[f].title : null,
            result: this.offer[f] ? (this.offer[f].title !== null) : false,
            step: 'description'
          }
        }),
        ...this.languages.map(f => {
          return {
            label: this.$t('validate.description', { lang: f }),
            input: this.offer[f] ? this.offer[f].description : null,
            result: this.offer[f] ? (this.offer[f].description !== null) : false,
            step: 'description'
          }
        })
      ]
    },
    total_items: function () { return this.validation.length },
    total_completed_items: function () { return this.validation.filter((n) => n.result).length }
  },
  props: {
    offer: {
      type: Object,
      default: null
    },
    languages: {
      type: Array,
      default: () => ['EN']
    },
    language: {
      type: String,
      default: 'EN'
    }
  }

}
</script>
