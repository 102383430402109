<template>
  <div>
    <b-row
      v-for="(group,key) in tagOptions"
      :key="key"
      class="mb-3"
    >
      <b-col>
        <b>{{ $t(`labels.${ group.label.toLowerCase()}`) }}</b><br>
        {{ group.tags.map(f =>$t(`tags.${f.tag.toLowerCase()}`)).join('. ') }}
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { TagMixin } from '@/mixins/TagMixin'
export default {
  props: {
    values: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    }
  },
  mixins: [TagMixin],
  name: 'Tags',
  computed: {
    tagOptions: function () {
      return this.tagGroups.map((g) => {
        return {
          label: g,
          tags: this.values.filter(o => o.category === g && o.active && this.tags.includes(o.tag)).sort((a, b) => a.order - b.order)
        }
      })
    }
  }
}
</script>
