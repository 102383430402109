<template>
  <b-container
    class="m-0 px-0"
    v-if="offer"
  >
    <b-card-header
      header-bg-variant="white"
      header-border-variant="white"
      class="my-3"
    >
      <b-card-title>
        {{ offer.name }} - {{ offer[language].title }}
      </b-card-title>
      <b-card-sub-title>
        <b-button
          @click="goBack()"
        >
          <font-awesome-icon icon="arrow-left" /> {{ $t("buttons.back") }}
        </b-button>
      </b-card-sub-title>
    </b-card-header>
    <b-row>
      <b-col
        md="12"
        lg="8"
      >
        <b-carousel
          controls
          indicators
          img-height="300"
          img-width="600"
        >
          <b-carousel-slide
            v-for="(p, key) in offer.photos"
            :key="key"
            :img-src="carouselUrl(p.public_id)"
          />
        </b-carousel>

        <b-card-body>
          {{ offer[language].description || "-" }}
        </b-card-body>

        <acco-info
          :id="offer.acco"
          :language="language"
        />
      </b-col>
      <b-col
        lg="4"
        md="12"
      >
        <b-card-sub-title class="text-center h4">
          <span style="color:red;text-decoration:line-through">
            <span style="color:black">{{ eur(offer.normalrate,language) }}</span>
          </span>
        </b-card-sub-title>
        <b-card-title class="text-center h2">
          {{ eur(offer.rate, language) }} {{ $t("labels.pn") }}
        </b-card-title>
        <b-card-text class="text-center">
          {{ offer_period_description }}
        </b-card-text>

        <b-card-text class="text-center h5">
          <font-awesome-icon
            icon="star"
            class="text-dark"
            v-for="n in offer.stars"
            :key="n"
          />
        </b-card-text>

        <b-button
          block
          size="lg"
          class="my-2"
          variant="primary"
          @click="onBookNow"
        >
          {{ $t("buttons.book_now") }}
        </b-button>

        <google-map :center="offer.position" />
        <h4 class="my-3">
          {{ $t("labels.share_the_deal") }}
        </h4>
        <div class="share-network-list mt-4">
          <share-network
            v-for="network in networks"
            :network="network.network"
            :key="network.network"
            :style="{ backgroundColor: network.color }"
            :url="sharing.url"
            :title="sharing.title"
            :description="sharing.description"
            :quote="sharing.quote"
            :hashtags="sharing.hashtags"
            :twitter-user="sharing.twitterUser"
          >
            <font-awesome-icon
              :icon="network.icon"
              class="m-2"
            />
          </share-network>
          <b-input-group class="mb-3">
            <b-input
              readonly
              :value="sharing.url"
            />
            <b-input-group-append>
              <b-button
                variant="secondary"
                @click="() => copyURL(sharing.url)"
              >
                <!---<font-awesome-icon icon="copy" />--->
                {{ $t('buttons.copy') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>

        <tags
          :values="settings.tags"
          :tags="offer.tags"
        />
        <!---<font-awesome-icon
          v-for="i in offer.tags"
          :key="i"
          :title="i.toLowerCase()"
          class="mx-2"
          :icon="i.toLowerCase()"
        />--->
      </b-col>
    </b-row>

    <b-modal
      v-model="bookNowModal"
      :ok-title="$t('buttons.send')"
      :cancel-title="$t('buttons.cancel')"
      @ok="SendEmail"
      :title="$t('buttons.book_now')"
    >
      <b-card-text>
        {{ $t('prompts.form_instruction') }}
      </b-card-text>
      <booking-form
        :booker="booker"
        :validation="fields"
        :rate="offer.rate"
        :min="$moment(offer.from).toDate()"
        :max="$moment(offer.till).toDate()"
      />
    </b-modal>
    <b-modal
      v-model="mailResult"
      ok-only
      @hide="mailResult=false"
    >
      {{ mailResultMessage }}
    </b-modal>
  </b-container>
</template>
<script>
import { db } from '@/firebase'
import store from '@/store'
import { Cloudinary } from 'cloudinary-core'
import AccoInfo from '../components/AccoInfo.vue'
import GoogleMap from '../components/GoogleMap.vue'
import { FormattingMixin } from '../mixins/FormattingMixin'
import { TagMixin } from '../mixins/TagMixin'
import BookingForm from '../components/BookingForm.vue'
import Tags from '../components/Tags'

const cl = new Cloudinary({ cloud_name: 'booknites' })
export default {
  mixins: [FormattingMixin, TagMixin],

  props: {
    offerid: {
      type: String,
      default: null
    },
    settings: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      offer: null,
      mailResultMessage: null,
      mailResult: false,
      fields: {
        title: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        period: null
      },
      booker: {
        title: null,
        firstname: null,
        lastname: null,
        rooms: 1,
        email: null,
        phone: null,
        persons: 2,
        period: {
          from: null,
          till: null
        },
        message: null
      },
      networks: [
        {
          network: 'facebook',
          name: 'Facebook',
          icon: ['fab', 'facebook'],
          color: '#1877f2'
        },
        { network: 'email', name: 'Email', icon: 'envelope', color: '#333333' },
        /* {
          network: 'pinterest',
          name: 'Pinterest',
          icon: ['fab', 'pinterest'],
          color: '#bd081c'
        },
        { network: 'sms', name: 'SMS', icon: 'comment-dots', color: '#333333' },
        {
          network: 'twitter',
          name: 'Twitter',
          icon: ['fab', 'twitter'],
          color: '#1da1f2'
        }, */
        {
          network: 'whatsapp',
          name: 'Whatsapp',
          icon: ['fab', 'whatsapp'],
          color: '#25d366'
        }
      ],
      bookNowModal: false
    }
  },
  watch: {
    offerid: {
      immediate: true,
      handler: function (id) {
        console.log('ophalen', id)
        this.$bind('offer', db.collection('ads').doc(id))
      }
    }
  },
  components: {
    AccoInfo,
    GoogleMap,
    BookingForm,
    Tags
  },
  computed: {
    offertags: function () {
      return this.offer.tags.map((tag) => {
        return {
          label: this.$t(`tags.${tag.toLowerCase()}`)
        }
      })
    },
    period_description: function () {
      var m = this.$moment
      return this.$t('prompts.period_description', {
        from: m(this.booker.period.from).isValid()
          ? m(this.booker.period.from)
            .locale(this.language.toLowerCase())
            .format('L')
          : '-',
        till: m(this.booker.period.till).isValid()
          ? m(this.booker.period.till)
            .locale(this.language.toLowerCase())
            .format('L')
          : '-'
      })
    },
    offer_period_description: function () {
      var m = this.$moment
      return this.$t('prompts.period_description', {
        from: m(this.offer.from).isValid()
          ? m(this.offer.from)
            .locale(this.language.toLowerCase())
            .format('LL')
          : '-',
        till: m(this.offer.till).isValid()
          ? m(this.offer.till).format('LL')
          : '-'
      })
    },
    language: function () {
      return store.getters.language
    },
    template: function () {
      return `booking-${this.language.toUpperCase()}`
    },
    sharing: function () {
      return {
        // url: `https://booknites.com/#${this.$route.fullPath}`,
        url: `https://booknites.com/offer/${this.offer.id}`,
        title: this.offer.EN.title,
        decription: this.offer.EN.description,
        // quote: 'geen quote',
        hashTags: 'hashtag1,hashtag2',
        twitterUrer: 'BookNitesTwitterAccount'
      }
    },
    check: function () {
      return {
        title: this.booker.title != null,
        firstname: this.booker.firstname != null,
        lastname: this.booker.lastname != null,
        email: this.booker.email != null,
        phone: this.booker.phone != null,
        period: (this.booker.period.from !== '' && this.booker.period.till !== '')
      }
    }
  },
  methods: {
    async copyURL (mytext) {
      try {
        await navigator.clipboard.writeText(mytext)
        alert(this.$t('prompts.copied'))
      } catch ($e) {
        // alert('Cannot copy')
      }
    },
    carouselUrl (publicId) {
      return cl.url(publicId, {
        secure: true,
        width: 600,
        height: 250,
        crop: 'fill'
      })
    },
    onBookNow () {
      if (this.booker.message !== this.$t('prompts.booknow_message')) { this.booker.message = this.$t('prompts.booknow_message') }
      this.bookNowModal = true
    },
    goBack () {
      this.$router.go(-1)
    },
    SendEmail (e) {
      e.preventDefault()
      var vm = this
      this.fields = this.check
      console.log(Object.values(this.fields))
      console.log(Object.values(this.fields).filter(n => (n === false)).length)
      if (Object.values(this.fields).filter(n => (n === false)).length === 0) {
        db.collection('mail').add({
          to: this.offer.contact.email,
          cc: this.booker.email,
          template: {
            name: 'booking-EN',
            data: {
              booker: { ...this.booker },
              period: this.period_description,
              ad: {
                title: this.offer.EN.title,
                rate: this.offer.rate,
                valid: this.offer_period_description,
                id: this.offer.id
              }
            }
          }
        }).then((response) => {
          console.log(response)
          vm.mailResultMessage = vm.$t('prompts.email_send')
          vm.mailResult = true
        }).catch((error) => {
          console.log(error)
          vm.mailResultMessage = vm.$t('prompts.email_error')
          vm.mailResult = true
        })
          .finally(() => { vm.bookNowModal = false })
      }
    }
  },
  name: 'DetailOffer'
}
</script>
<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
h1 {
  text-align: center;
  margin: 50px 0 80px;
}
.share-network-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
}
a[class^="share-network-"] {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
}
a[class^="share-network-"] .fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}
a[class^="share-network-"] span {
  padding: 0 10px;
  flex: 1 1 0%;
  font-weight: 500;
}
</style>
