import Vue from 'vue'
import Vuex from 'vuex'
import { bootstrap } from 'vue-gtag'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null
    },
    skipWelcome: false,
    currentLanguage: 'EN'
  },
  getters: {
    user (state) {
      return state.user
    },
    language (state) {
      return state.currentLanguage
    },
    locale (state) {
      return `${state.currentLanguage.toLowerCase()}_${state.currentLanguage}`
    },
    dateLocale (state) {
      var locale = state.currentLanguage.toLowerCase()
      return (locale === 'en') ? 'en-GB' : 'nl-NL'
    },
    skipWelcome (state) {
      return (state.skipWelcome)
    },
    hasCookiePermission (state) {
      return (state.cookiePermission === 'accept')
    }
  },
  mutations: {
    SET_LOGGED_IN (state, value) {
      state.user.loggedIn = value
    },
    SET_USER (state, data) {
      state.user.data = data
    },
    SET_LANGUAGE (state, data) {
      console.log(data)
      state.currentLanguage = data
      localStorage.setItem('language', data)
    },
    SET_COOKIE_PERMISSION (state, data) {
      state.cookiePermission = data
      if (data === 'accept') {
        bootstrap().then((gtag) => {
          console.log('tracking started')
          // console.dir(gtag)
        })
      }
    },
    // SET_SKIP_WELCOME (state, data) {
    //   state.skipWelcome = data
    //   localStorage.setItem('skipWelcome', data)
    // },
    initialiseStore (state) {
      // if (localStorage.getItem('language')) {
      //   state.currentLanguage = localStorage.getItem('language')
      // }
      // if (localStorage.getItem('skipWelcome')) {
      //   state.skipWelcome = localStorage.getItem('skipWelcome')
      // }
    }
  },
  actions: {
    fetchUser ({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified
        })
      } else {
        commit('SET_USER', null)
      }
    },
    changeLanguage ({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    setCookiePermission ({ commit }, choice) {
      commit('SET_COOKIE_PERMISSION', choice)
    }
    // setSkipWelcome ({ commit }, choice) {
    //   commit('SET_SKIP_WELCOME', choice)
    // }
  }
})
