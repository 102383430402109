<template>
  <b-card
    v-b-hover="hoverHandler"
    @click="() => {}"
    :bg-variant="isHovered ? 'primary' : 'none'"
    no-body
  >
    <b-card-text>
      <b-form-row>
        <b-col>
          <b-img
            :src="offerThumb"
            width="170"
          />
        </b-col>
        <b-col class="my-3">
          <b-container
            class="p-0"
            align="center"
            v-if="offer"
          >
            <span style="color:red;text-decoration:line-through">
              <span style="color:black">{{ eur(offer.normalrate,language) }}</span>
            </span>
            <h5>
              {{ eur(offer.rate,language) }}
            </h5>
            <b-card-sub-title>
              {{ $t("labels.pn") }}
            </b-card-sub-title>
            <h3>
              <b-badge
                pill
                v-if="offer.discount"
              >
                {{ `-${offer.discount}%` }}
              </b-badge>
            </h3>
            <b-card-text class="text-center h6">
              <font-awesome-icon
                icon="star"
                class="text-dark"
                v-for="n in offer.stars"
                :key="n"
              />
            </b-card-text>
          </b-container>
        </b-col>
      </b-form-row>
      <b-card-body body-bg-variant="grey">
        <b-card-text v-if="offer[language]">
          <!---{{ Object.keys(offer.types).filter((key) => offer.types[key]).join(', ') }} - --->
          {{ offer[language].title }}
        </b-card-text>
      </b-card-body>
      <b-card-body>
        <b-card-title class="h5">
          {{ offer.name }}
        </b-card-title>
        <b-card-sub-title>
          {{ offer.location }}, {{ offer.country }}
        </b-card-sub-title>
        <b-card-text>
          {{ $t('form.booknow_valid', {
            from:$moment(offer.from).locale($store.getters.dateLocale).format("L"),
            till: $moment(offer.till).locale($store.getters.dateLocale).format("L")
          }) }}
        </b-card-text>
      </b-card-body>
      <b-link
        variant="primary"
        class="stretched-link"
        @click="$emit('click', offer)"
      />
    </b-card-text>
  </b-card>
</template>
<script>
import { FormattingMixin } from '../mixins/FormattingMixin'
import { Cloudinary } from 'cloudinary-core'
const cl = new Cloudinary({ cloud_name: 'booknites' })
export default {
  name: 'Ad',
  components: {},
  mixins: [FormattingMixin],
  props: {
    offer: {
      type: Object,
      default: null
    },
    language: {
      type: String,
      default: 'EN'
    }
  },
  data () {
    return {
      noimg: 'no-image-available_pecjtz.jpg',
      isHovered: false
    }
  },

  computed: {
    offerThumb: function () {
      if (!this.offer.photos) {
        return cl.url(this.noimg, {
          secure: true,
          width: 350,
          height: 350,
          crop: 'fill'
        })
      }
      if (this.offer.photos.length === 0) {
        return cl.url(this.noimg, {
          secure: true,
          width: 350,
          height: 350,
          crop: 'fill'
        })
      }
      return cl.url(this.offer.photos[0].public_id, {
        secure: true,
        width: 350,
        height: 350,
        crop: 'fill'
      })
    }
  },
  watch: {},
  mounted () {},
  methods: {
    hoverHandler (value) {
      this.isHovered = value
    }
  }
}
</script>
