<template>
  <b-container>
    <vue-select-image
      :data-images="photos.map(f => { return { id: f.id, src: f.url, alt: f.node[language], node: f.node }})"
      :is-multiple="true"
      use-label
      w="200"
      :selected-images="[]"
      @onselectmultipleimage="$emit('update', $event.map(f => f.node))"
    />
  </b-container>
</template>
<script>

export default {
  name: 'PhotoSelector',
  props: {
    photos: {
      type: Array,
      default: () => []
    },
    language: {
      type: String,
      default: 'NL'
    }
  },
  mounted () {
    this.$emit('update', [])
  }
}
</script>
