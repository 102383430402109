<template>
  <b-overlay :show="isBusy">
    <b-form-group :description="$t('file.maxupload')">
      <b-img
        width="100"
        :src="dataUrl"
      />
      <b-form-file
        v-model="selectedFile"
        :placeholder="$t('file.placeholder')"
        accept=".jpg, .png, .jpeg"
        @change="handleFiles($event.target.files[0])"
        :browse-text="$t('file.choose')"
      />
      <b-button
        v-if="dataUrl"
        @click="doUpload"
      >
        {{ $t('file.upload') }}
      </b-button>
      <b-alert
        variant="danger"
        class="my-2"
        :show="error !== null"
      >
        {{ error }}
      </b-alert>
    </b-form-group>
  </b-overlay>
</template>
<script>
import { functions } from '@/firebase'
export default {
  name: 'CloudUpload',
  data () {
    return {
      dataUrl: null,
      result: null,
      isBusy: false,
      selectedFile: null,
      error: null
    }
  },
  methods: {
    handleFiles (file) {
      console.log(file)
      const reader = new FileReader()

      this.error = null

      if (file.size > 1024 * 1024) {
        this.error = 'File too big (> 1MB)'
        this.selectedFile = null
        return
      }

      reader.addEventListener('load', () => {
        this.dataUrl = reader.result
        this.doUpload()
      }, false)

      reader.readAsDataURL(file)
    },
    doUpload () {
      // Get Cloud function named Upload
      var upload = functions.httpsCallable('upload')
      const data = {
        dataUrl: this.dataUrl
      }
      var vm = this
      vm.isBusy = true
      // Upload Image to Cloudinary
      upload(JSON.stringify(data))
        .then(response => {
          vm.result = JSON.parse(response.data)
          vm.selectedFile = null
          vm.$emit('uploaded', vm.result)
          vm.dataUrl = null
        })
        .catch((error) => {
        // Return any error message
          vm.result = error
        }).finally(() => { vm.isBusy = false })
    }

  }
}
</script>
