import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import store from './store'

// Get a Firestore instance
export const app = firebase
  .initializeApp({
    apiKey: 'AIzaSyDp8P2uYGNcF89ibqjNpxi2O-sAYCT3yGI',
    authDomain: 'book-nites-dev.firebaseapp.com',
    projectId: 'book-nites-dev',
    storageBucket: 'book-nites-dev.appspot.com',
    messagingSenderId: '55708811353',
    appId: '1:55708811353:web:c87112ebaa00cb47f47148',
    measurementId: 'G-YBGZNW76EK'
  })

app.auth().onAuthStateChanged(user => {
  console.log('onAuthChange')
  store.dispatch('fetchUser', user)
})

export const db = app.firestore()
export const auth = app.auth()
export const functions = app.functions('europe-west1') // .useEmulator('localhost', 5001)
export const storage = app.storage()

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

// if using Firebase JS SDK < 5.8.0
db.settings({ timestampsInSnapshots: true })
