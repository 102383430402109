<template>
  <b-container
    class="text-white pt-2"
  >
    <b-form-group
      class="text-white"
      :label="$t('labels.destination')"
    >
      <b-form-row v-if="preset == null">
        <b-button-group class="d-flex w-100 flex-wrap">
          <b-button
            v-for="(n,index) in settings.countries"
            :key="index"
            variant="outline-light"
            @click="() => { preset = n; gotoPreset(n) }"
            :active="preset == n"
            size="lg"
            class="text-white btn-primary-country"
          >
            {{ n[lang] }}
          </b-button>
        </b-button-group>
      </b-form-row>
      <b-form-row v-else>
        <b-col
          lg="6"
          sm="12"
          class="mb-2"
        >
          <b-input-group>
            <b-input
              readonly
              :value="preset[lang]"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="clearLocation()"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          lg="3"
          sm="12"
          class="mb-2"
        >
          <b-input-group>
            <b-form-datepicker
              :locale="$store.getters.dateLocale"
              :date-format-options=" { year: 'numeric', month: 'long', day: 'numeric' }"
              :placeholder="$t('prompts.start')"
              v-model="start"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="start = null;"
              >
                <font-awesome-icon icon="times" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          lg="3"
          md="12"
          class="mb-2"
        >
          <b-button
            @click="zoeken"
            variant="primary"
            block
          >
            <font-awesome-icon icon="search" /> {{ $t('buttons.search') }}
          </b-button>
        </b-col>
      </b-form-row>
    </b-form-group>
    <!---<b-button
      @click="() => { preset= null; clearLocation(); }"
      v-if="preset != null"
      pill
      size="sm"
      variant="primary"
    >
      <font-awesome-icon icon="times" /> {{ preset[lang] }}
    </b-button>--->
  </b-container>
</template>
<script>
// import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { db } from '@/firebase'
export default {
  components: {
    // VueGoogleAutocomplete
  },
  name: 'SearchOffers',
  computed: {
    locationEndpoint: function () { return this.preset.placeId },
    hasLocation: function () { return (this.preset !== null) },
    bounds: function () { return (this.hasLocation) ? this.location.geometry.bounds : null },
    hasPeriod: function () { return (this.start !== null) },
    lang: function () { return this.$store.getters.language.toLowerCase() }
  },
  data () {
    return {
      location: null,
      start: null,
      settings: {},
      preset: null
    }
  },
  firestore: {
    settings: db.doc('settings/default')
  },
  methods: {
    clearLocation () {
      this.location = null
      this.start = null
      this.preset = null
      this.$router.push({
        name: 'HomeDefault'
      })
      // this.$emit('onUpdateLocation', { image: 'https://res.cloudinary.com/booknites/image/upload/v1646121234/assets/skyline-world_xpwbf7.png' })
      this.$emit('onUpdateLocation', { image: null })
    },
    zoeken () {
      var routeName = 'ZoekenPeriodeLocatie'
      if (!this.hasLocation) routeName = 'ZoekenPeriode'
      if (!this.hasPeriod) routeName = 'ZoekenLocatie'
      console.log(routeName)
      this.$router.push({
        name: routeName,
        params: {
          location: this.locationEndpoint,
          period: this.start,
          c: null
        }
      })
    },
    gotoPreset (preset) {
      this.$router.push({
        name: 'ZoekenLocatie',
        params: {
          location: preset.placeId,
          period: this.start,
          c: null
        }
      })
      this.$emit('onUpdateLocation', preset)
    },
    getAddressData (addressData, placeResultData, id) {
      console.log('getAddressData')
      console.log(addressData)
      console.log(placeResultData)
      console.log(id)
      this.location = placeResultData
      // this.zoeken()
    }
  }
}
</script>
