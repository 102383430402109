<template>
  <b-container class="mx-0 my-3 px-0">
    <b-row>
      <b-col
        lg="4"
        md="6"
        sm="12"
        v-for="(offer, index) in offers"
        :key="index"
      >
        <ad
          class="mb-4"
          :offer="offer"
          :language="language"
          @click="displayOffer(offer)"
        >
          <b-badge>
            {{ eur(offer.rate) }} {{ $t("labels.pn") }}
            <b-badge variant="danger">
              -{{ offer.discount }}%
            </b-badge>
          </b-badge>
        </ad>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <b-button
          size="md"
          class="ml-auto mb-2 float-right"
          variant="primary"
          @click="() => getResults(true)"
        >
          {{ $t('buttons.load_more') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { db } from '@/firebase'
import store from '@/store'
import Ad from '../components/Ad.vue'

export default {
  name: 'AdGrid',
  components: {
    Ad
  },
  watch: {
    $route (to, from) {
      this.getResults(false)
    }
  },
  props: {
    location: {
      type: String,
      default: null
    },
    period: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      offers: [],
      lastVisible: null,
      perPage: 12
    }
  },
  mounted () {
    this.getResults(true)
  },
  methods: {
    displayOffer (offer) {
      this.$router.push({
        name: 'DetailOffer',
        params: { offerid: offer.id }
      })
    },
    eur (value) {
      return new Intl.NumberFormat(
        `${this.language.toLowerCase()}-${this.language.toUpperCase()}`,
        { style: 'currency', currency: 'EUR' }
      ).format(value)
    },
    getResults (append) {
      var vm = this
      var query = this.q

      if (!append) {
        this.offers = []
        this.lastVisible = null
      }

      if (this.lastVisible !== null) query = query.startAfter(this.lastVisible)

      query.get().then(querySnapshot => {
        vm.offers.push(...querySnapshot.docs.map(function (doc) {
          return { ...doc.data(), id: doc.id }
        }))
        console.log(querySnapshot.size)
        vm.lastVisible = querySnapshot.docs[querySnapshot.size - 1]
      })
    }
  },
  computed: {
    language: function () {
      return store.getters.language
    },
    q: function () {
      var query = db.collection('ads')
      if (this.period) {
        query = query.where('till', '>=', this.period).orderBy('till', 'asc')
      } else {
        query = query.orderBy('on', 'desc')
      }
      if (this.location) { query = query.where('pointers', 'array-contains', this.location) }
      query = query.limit(this.perPage)
      return query
    }
  }
}
</script>
