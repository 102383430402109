<template>
  <b-container>
    <b-row class="justify-content-center">
      <b-col
        xl="9"
        lg="12"
      >
        <b-card-group>
          <b-card :title="$t('navigation.login')">
            <b-card-body v-if="content">
              <StoryblokComponent
                :blok="blok"
                :key="blok._uid"
                v-for="blok in content.body"
              />
            </b-card-body>
          </b-card>
          <register />
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>

import Register from './Register.vue'
export default {
  components: { Register },
  name: 'Login',
  data () {
    return {
      result: null,
      isError: false,
      content: null
    }
  },
  computed: {
    lang: function () {
      return this.$root.$i18n.locale
    }
  },
  watch: {
    lang: {
      handler: function () {
        this.getContent()
      }
    }
  },
  mounted () {
    this.getContent()
  },
  methods: {
    getContent () {
      console.log('lang', this.lang)
      this.$storyblokApi.get('cdn/stories/login', {
        version: 'published',
        language: this.lang
      }).then((response) => {
        console.log(response)
        this.content = response.data.story.content
        console.log('content', this.content)
      })
    }
  }
}
</script>
