<template>
  <b-form-group
    label-size="lg"
    :label="label"
    label-cols="4"
  >
    <b-collapse :visible="open">
      <slot name="open" />
    </b-collapse>
    <b-collapse :visible="!open">
      <slot />
    </b-collapse>
  </b-form-group>
</template>
<script>
export default {
  name: 'OfferEditGroup',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'none'
    }
  }
}
</script>
