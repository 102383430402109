<template>
  <b-row>
    <b-col>
      <b-card class="my-4">
        {{ $t('tabs.photos_explained') }}
      </b-card>

      <cloud-upload v-if="media.length < 12" />
      <b-alert
        v-else
        show
      >
        Maximaal aantal foto's bereikt!
      </b-alert>
      <b-card-group columns>
        <b-card
          v-for="(img,index) in fotoThumbs"
          :key="index"
          :img-src="img.url"
          img-top
          no-body
        >
          <b-card-body>
            <text-edit
              v-for="l in profile.languages"
              :key="l"
              :text="media[index][l]"
              @save="SaveCaption(media[index],l,$event)"
              :placeholder="$t('labels.caption',{lang: l})"
              class="mb-1"
            />
            {{ $moment(img.node.created_at).format('LLL') }}
          </b-card-body>
          <b-card-footer>
            <b-button-group>
              <b-button
                variant="icon"
                :class="img.public_id === currentProfileImageId ? 'text-primary' : 'ghost icon'"
                @click="SetAsAvatar(img.node)"
              >
                <font-awesome-icon
                  v-if="img.public_id === currentProfileImageId"
                  icon="check"
                /> instellen als profielfoto
              </b-button>
              <b-button
                variant="icon"
                @click="deletefoto(img.id)"
                class="text-danger float-right"
              >
                <font-awesome-icon icon="trash" /> {{ $t('buttons.delete') }}
              </b-button>
            </b-button-group>
          </b-card-footer>
        </b-card>
      </b-card-group>
    </b-col>
  </b-row>
</template>
<script>
import { auth, db } from '@/firebase'
import { LanguageMixin } from '@/mixins/LanguageMixin'
import CloudUpload from '../components/CloudUpload.vue'
import { Cloudinary } from 'cloudinary-core'
import TextEdit from '../components/TextEdit.vue'
const cl = new Cloudinary({ cloud_name: 'booknites' })

export default {
  mixins: [LanguageMixin],
  components: {
    CloudUpload,
    TextEdit
  },
  computed: {
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    profileMediaUrl: function () {
      return `/profiles/${this.userId}`
    },
    currentProfileImageId: function () {
      if (!this.profile.img) return
      return this.profile.img.public_id
    },
    fotoThumbs: function () {
      return this.media.map(f => {
        return {
          url: cl.url(f.public_id, {
            secure: true,
            width: 350,
            height: 350,
            crop: 'fill'
          }),
          createdOn: f.createdOn,
          public_id: f.public_id,
          id: f.id,
          node: f
        }
      })
    }
  },
  name: 'ProfileFotos',
  data () {
    return {
      selectedFile: null,
      isBusy: false,
      uploadFile: null
    }
  },
  props: ['profile', 'media'],
  methods: {
    SetAsAvatar (p) {
      db.doc(`${this.profileMediaUrl}`).update({ img: p })
    },
    deletefoto (id) {
      db.collection('profiles').doc(this.userId).collection('media').doc(id).delete()
    },
    SaveCaption (foto, language, value) {
      var caption = {}
      caption[language] = value
      db.collection('profiles').doc(this.userId).collection('media').doc(foto.id).update(caption)
    }
  }
}
</script>
