<template>
  <GmapMap
    :center="center"
    :zoom="13"
    :bounds="bounds"
    ref="map"
    style="width: 100%; height: 300px"
  >
    <GmapMarker :position="center" />
  </GmapMap>
</template>
<script>
export default {
  props: {
    bounds: {
      type: Object,
      default: null
    },
    center: {
      type: Object,
      default: () => { return { lat: 52.401950, lng: 4.891720 } }
    }
  },
  watch: {
    bounds: function (bounds) {
      this.$refs.map.$mapPromise.then((map) => {
        console.log(bounds)
        map.fitBounds(bounds)
      })
    }
  },
  name: 'GoogleMap'
}
</script>
