var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('labels.destination')}},[_c('vue-google-autocomplete',{attrs:{"id":"map","classname":"form-control","types":"(regions)","fields":[
            'address_components',
            'adr_address',
            'alt_id',
            'formatted_address',
            'geometry',
            'icon',
            'id',
            'name',
            'business_status',
            'photo',
            'place_id',
            'scope',
            'type',
            'url',
            'utc_offset_minutes',
            'vicinity' ],"placeholder":_vm.$t('prompts.search_bar')},on:{"placechanged":_vm.getAddressData}},[_c('input')])],1)],1),_c('b-col',[(_vm.isCountry)?_c('b-card',[_c('b-button',{on:{"click":function($event){_vm.saveKey(_vm.place.place_id, _vm.keyFormat(_vm.place, 'COUNTRY'))}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.place.name)+" toevoegen ("+_vm._s(_vm.place.address_components[0].short_name)+") ")],1)],1):(_vm.isRegion)?_c('b-card',[_c('b-button',{on:{"click":function($event){_vm.saveKey(_vm.place.place_id, _vm.keyFormat(_vm.place, 'REGION'))}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.place.name)+" streek toevoegen ("+_vm._s(_vm.place.address_components[0].short_name)+") ")],1)],1):(_vm.place !== null)?_c('b-card',[_c('b-button',{on:{"click":function($event){_vm.saveKey(_vm.place.place_id, _vm.keyFormat(_vm.place, 'CITY'))}}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.place.name)+" stad toevoegen ("+_vm._s(_vm.place.address_components[0].short_name)+") ")],1)],1):_vm._e(),_c('pre',[_vm._v("          Resultaat:\n          "+_vm._s(_vm.place)+"\n        ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }