<template>
  <b-table
    :items="payments"
    :fields="['VerlooptOp','PaidAt','type','status','Omschrijving','Actie']"
  >
    <template v-slot:cell(VerlooptOp)="row">
      {{ $moment(row.item.expiresAt).format('LLL') }}
    </template>
    <template v-slot:cell(PaidAt)="row">
      {{ $moment(row.item.paidAt).format('LLL') }}
    </template>
    <template v-slot:cell(Omschrijving)="row">
      {{ row.item.invoice }}
    </template>
    <template v-slot:cell(Actie)="row">
      <b-button
        :disabled="!hasInvoiceData(row.item)"
        @click="() => sendFactuur(paymentUrl(row.item))"
      >
        Verstuur factuur naar {{ hasInvoiceData(row.item) ? row.item.invoice.customer.email : '-geen data-' }}
      </b-button>
    </template>
  </b-table>
</template>

<script>
import { db, functions } from '@/firebase'
export default {
  name: 'PaymentsList',
  components: {},
  mixins: [],
  props: {
  },
  data () {
    return {
      payments: []
    }
  },
  firestore: {
    // payments: db.collectionGroup('payments').where('status', '==', 'paid').orderBy('paidAt', 'desc').limit(15)
    payments: db.collectionGroup('payments').orderBy('expiresAt', 'desc').limit(15)
  },
  computed: {},
  watch: {},
  mounted () {

  },
  methods: {
    sendFactuur (itemUrl) {
      console.log(itemUrl)
      var invoice = functions.httpsCallable('generateInvoice')
      invoice({ path: itemUrl })
    },
    hasInvoiceData (item) {
      return item.invoice != null
    },
    paymentUrl (item) {
      return `${item.metadata.orderurl}/payments/${item.id}`
    }
  }

}
</script>
