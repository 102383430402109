<template>
  <b-overlay
    :show="isWorking"
    v-if="showProgress"
  >
    <div v-if="visible">
      {{ result }}
    </div>
    <slot />
  </b-overlay>
</template>
<script>
import Geocoder from '@pderas/vue2-geocoder'
import Vue from 'vue'

Vue.use(Geocoder, {
  defaultCountryCode: null, // e.g. 'CA'
  defaultLanguage: null, // e.g. 'en'
  defaultMode: 'address', // or 'lat-lng'
  googleMapsApiKey: 'AIzaSyACFqeIBU81ElQmpgI8_G7-HxuxMwl1w9U'
})

export default {
  name: 'GeoCoder',
  data () {
    return {
      position: null,
      isWorking: false,
      result: null,
      bounds: null
    }
  },
  props: {
    adres: {
      type: Object,
      default: () => {}
    },
    showProgress: {
      type: Boolean,
      default: true
    },
    country: {
      type: String,
      default: null
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    country: {
      immediate: true,
      handler: function (code) {
        Vue.$geocoder.setDefaultCountry(code.toLowerCase())
      }
    },
    adres: {
      immediate: true,
      handler: function (obj) {
        var t = this
        t.isWorking = true
        Vue.$geocoder.setDefaultMode('address')
        Vue.$geocoder.send(obj, (response) => {
          console.log(response)
          if (response.results.length === 1) {
            t.result = response.results
            t.position = response.results[0].geometry.location
            t.bounds = response.results[0].geometry.bounds
            t.$emit('input', response.results[0].geometry.location)
            t.$emit('place_id', response.results[0].place_id)
            t.isWorking = false
          }
        })
      }
    }
  },
  methods: {
    setDefaultCountry () {
      console.log(this.country)
      Vue.$geocoder.setDefaultCountryCode(this.country)
    }
  }
}
</script>
