<template>
  <b-overlay :show="busy">
    <b-card v-if="selectedService != null">
      {{
        $t("services.explained", {
          maxdays: selectedService.maxdays,
          price: eur(selectedService.price, language),
        })
      }}
    </b-card>
    <b-row
      v-if="offer && offer.status == 'CONCEPT'"
      align-h="center"
    >
      <b-col cols="10">
        <h3 class="my-4">
          {{ $t("buttons.new_ad") }} {{ offer.status }}
        </h3>
        <b-form-group
          label-size="lg"
          :label="$t('form.title_description')"
          label-cols="4"
          v-if="profile.languages.length > 0"
        >
          <b-form-group :state="!profile.languages.includes(language)">
            <language-tabs
              :languages="
                profile.languages.map((f) => {
                  return { code: f, valid: HasLanguage(f) };
                })
              "
              :selected="language"
              @input="$emit('change-language', $event)"
            />
          </b-form-group>
          <b-collapse :visible="!steps.description">
            <h5>{{ block.title }}</h5>
            <b-card-text>{{ block.description }}</b-card-text>
            <b-button @click="() => focusBlock('description')">
              <font-awesome-icon icon="edit" /> {{ $t("buttons.edit") }}
            </b-button>
          </b-collapse>

          <b-collapse v-model="steps.description">
            <b-form-group
              :label="$t('prompts.title_for', { lang: language })"
              :description="`${
                block.title ? block.title.length : 0
              }/${maxTitleLength}`"
            >
              <b-input
                :placeholder="$t('prompts.title')"
                size="lg"
                :state="hasTitle"
                v-model="block.title"
                :maxlength="maxTitleLength"
              />
            </b-form-group>

            <b-form-group :label="$t('prompts.desc_for', { lang: language })">
              <b-textarea
                rows="7"
                :placeholder="$t('prompts.description')"
                :state="hasDescription"
                v-model="block.description"
              />
            </b-form-group>
            <b-button
              variant="primary"
              class="float-right"
              @click="() => focusBlock('period')"
            >
              {{ $t(progress.label) }}
              <font-awesome-icon :icon="progress.icon" />
            </b-button>
          </b-collapse>
        </b-form-group>
        <hr class="py-4">
        <b-form-group
          label-size="lg"
          :label="$t('form.period_rate')"
          label-cols="4"
        >
          <b-collapse :visible="!steps.period">
            <b-card title="">
              <b-card-body>
                {{ $moment(offer.from).format("LL") }} -
                {{ $moment(offer.till).format("LL") }}
              </b-card-body>
              <b-card-body>
                {{ eur(offer.rate, language) }} (-{{ offer.discount }}%
                <small> normaal: {{ eur(offer.normalrate, language) }}</small>)
              </b-card-body>
            </b-card>

            <b-button @click="() => focusBlock('period')">
              <font-awesome-icon icon="edit" /> {{ $t("buttons.edit") }}
            </b-button>
          </b-collapse>

          <b-collapse v-model="steps.period">
            <tarif-period
              :offer="offer"
              :services="services"
              @service-selected="service = $event"
            />
            <b-button
              variant="primary"
              class="float-right"
              @click="
                () => focusBlock(photos.length == 0 ? 'media' : 'contact')
              "
            >
              {{ $t(progress.label) }}
              <font-awesome-icon :icon="progress.icon" />
            </b-button>
          </b-collapse>
        </b-form-group>
        <hr class="py-4">
        <b-form-group
          label-size="lg"
          :label="$t('form.choose_photos')"
          label-cols="4"
        >
          <b-alert
            show
            v-if="fotoThumbs.length == 0"
            variant="secondary"
          >
            {{ $t("warning.no_photos_selected") }}!
          </b-alert>

          <b-card-group columns>
            <b-card
              no-body
              v-for="(f, i) in fotoThumbs"
              :key="i"
              :img-src="f.url"
              img-top
            >
              <b-button
                variant="icon"
                @click="offer.photos.splice(i, 1)"
                class="text-danger float-right"
              >
                <font-awesome-icon icon="trash" />
              </b-button>
            </b-card>
          </b-card-group>
          <b-collapse :visible="!steps.media">
            <b-button @click="focusBlock('media')">
              <font-awesome-icon icon="edit" /> {{ $t("buttons.edit") }}
            </b-button>
          </b-collapse>

          <b-collapse v-model="steps.media">
            <b-button
              variant="primary"
              class="float-right"
              @click="() => focusBlock('contact')"
            >
              {{ $t(progress.label) }}
              <font-awesome-icon :icon="progress.icon" />
            </b-button>
          </b-collapse>
        </b-form-group>
        <b-modal
          size="xl"
          :title="$t('prompts.choose_photos')"
          v-model="showFotoPicker"
          :ok-title="$t('buttons.selection_ok')"
          ok-only
          @ok="
            $set(offer, 'photos', selectiePhotos);
            focusBlock('contact');
          "
        >
          <cloud-upload v-if="media.length < 12" />
          <b-alert
            v-else
            show
          >
            Maximaal aantal foto's bereikt!
          </b-alert>
          <photo-selector
            :photos="MapThumbs(media)"
            :language="language"
            :offer="offer"
            @update="selectiePhotos = $event"
          />

          <b-card-body v-if="selectiePhotos.length > 0">
            {{ selectiePhotos.length }}
            {{ $tc("prompts.photos_selected", selectiePhotos.length) }}
            <b-card-group>
              <b-img
                v-for="(f, index) in selectiePhotos"
                :src="f.secure_url"
                height="100"
                width="100"
                :key="index"
              />
            </b-card-group>

            {{ showFotoPicker }}
          </b-card-body>
        </b-modal>
        <hr class="py-4">
        <collapsable-form-group
          :open="steps.contact"
          :label="$t('form.contact')"
        >
          <b-card-text>
            {{ offer.bookingemail || profile.bookingemail }}
            {{ offer.reference }}
          </b-card-text>
          <b-button @click="() => focusBlock('contact')">
            <font-awesome-icon icon="edit" /> {{ $t("buttons.edit") }}
          </b-button>

          <template v-slot:open>
            <b-form-row>
              <b-col>
                <b-form-group
                  :label="$t('form.bookingemail')"
                  :description="$t('form.bookingemail_description')"
                >
                  <b-input
                    v-model="offer.bookingemail"
                    :placeholder="profile.bookingemail"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('form.reference')"
                  :description="$t('form.not_public')"
                >
                  <b-input v-model="offer.reference" />
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-group
              :description="`${$t(
                'prompts.invoice_email_explained'
              )} ${invoiceEmail}`"
            >
              <b-checkbox
                v-model="invoiceToBookingmail"
              >
                {{ $t(
                  'prompts.invoice_email'
                ) }}
              </b-checkbox>
            </b-form-group>
            <b-button
              variant="primary"
              class="float-right"
              @click="() => focusBlock('preview')"
            >
              {{ $t(progress.label) }}
              <font-awesome-icon :icon="progress.icon" />
            </b-button>
          </template>
        </collapsable-form-group>
        <hr class="py-4">
        <collapsable-form-group
          :label="$t('form.preview_payment')"
          :open="steps.preview"
          class="my-4"
        >
          <b-button
            variant="primary"
            size="lg"
            class="float-right"
            @click="() => focusBlock('preview')"
          >
            {{ $t("buttons.preview") }} <font-awesome-icon icon="eye" />
          </b-button>

          <template v-slot:open>
            <offer-preview
              :offer="staged_offer"
              :languages="profile.languages"
              :language="language"
              @publish="PlaatsAdvertentie"
              @cancel="VerwijderAdvertentie"
              @goto="(step) => focusBlock(step)"
            />
          </template>
        </collapsable-form-group>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-row>
        <b-col>
          <ad
            :offer="offer"
            :language="language"
          />
        </b-col>
        <b-col>
          <b-alert show>
            {{ $t("prompts.ad_is_published") }}
          </b-alert>
        </b-col>
      </b-row>
    </b-row>
  </b-overlay>
</template>
<script>
import { auth, db, functions } from '@/firebase'
import PhotoSelector from '../components/PhotoSelector.vue'
import { Cloudinary } from 'cloudinary-core'
import LanguageTabs from '../components/LanguageTabs.vue'
import { CategoryMixin } from '../mixins/CategoryMixin'
import { TagMixin } from '../mixins/TagMixin'
import TarifPeriod from '../components/TarifPeriod.vue'
import CollapsableFormGroup from '../components/CollapsableFormGroup.vue'
import OfferPreview from './OfferPreview.vue'
import { FormattingMixin } from '../mixins/FormattingMixin'
import Ad from '../components/Ad.vue'
import CloudUpload from '../components/CloudUpload.vue'

const cl = new Cloudinary({ cloud_name: 'booknites' })

export default {
  components: {
    LanguageTabs,
    PhotoSelector,
    TarifPeriod,
    CollapsableFormGroup,
    OfferPreview,
    Ad,
    CloudUpload
  },
  name: 'OfferEdit',
  mixins: [CategoryMixin, TagMixin, FormattingMixin],
  data () {
    return {
      showFotoPicker: false,
      selectiePhotos: [],
      showTarifPeriodEditor: false,
      maxTitleLength: 80,
      payments: [],
      services: [],
      invoiceToBookingmail: false,
      defaultdays: 15,
      progress: {
        label: 'next',
        icon: 'arrow-right'
      },
      busy: false,
      steps: {
        description: true,
        period: false,
        media: false,
        contact: false,
        preview: false
      },
      settings: null
    }
  },
  firestore: {
    services: db.collection('services').where('active', '==', true),
    settings: db.doc('settings/default')
  },
  props: {
    url: {
      type: String,
      default: null
    },
    language: {
      type: String,
      default: 'EN'
    },
    offer: {
      type: Object,
      default: null
    },
    media: {
      type: Array,
      default: () => []
    },
    profile: {
      type: Object,
      default: null
    }
  },
  computed: {
    selectedService: function () {
      return [].concat(this.services).find((s) => s.maxdays === this.defaultdays)
    },
    mediaThumbs: function () {
      return this.MapThumbs(this.media)
    },
    invoiceEmail: function () {
      return this.invoiceToBookingmail
        ? this.offer.bookingemail
        : this.profile.bookingemail
    },
    accoTypes: function () {
      if (this.profile == null) return []
      if (this.settings == null) return []
      return this.settings.tags
        .filter((t) => t.category === 'type')
        .map((t) => t.tag)
    },
    staged_offer: function () {
      var offer = { ...this.offer }
      offer.position = this.profile.position
      offer.tags = this.profile.tags
      offer.pointers = this.profile.pointers
      offer.acco = this.profile.id
      offer.discountrange = this.GetDiscountRange(offer.discount)
      offer.raterange = this.GetRateRange(offer.rate)
      offer.starrange = this.GetStarsRanges(this.profile.stars)
      offer.name = this.profile.EN.name
      offer.stars = this.profile.stars
      offer.location = this.profile.location
      offer.country = this.profile.country
      offer.types = this.accoTypes
      offer.contact = {
        name: this.profile.bookingcontact || null,
        email: this.offer.bookingemail || this.profile.bookingemail,
        phone: this.profile.phonenumber
      }
      return offer
    },
    fotoThumbs: function () {
      return this.MapThumbs(this.offer.photos)
    },
    block: function () {
      if (!this.offer[this.language]) {
        this.$set(this.offer, this.language, {
          description: null,
          title: null
        })
      }
      return this.offer[this.language]
    },
    hasTitle: function () {
      return (this.block.title || '').length > 0
    },
    hasDescription: function () {
      return (this.block.description || '').length > 0
    },
    photos: function () {
      if (!this.offer.photos) this.$set(this.offer, 'photos', [])
      return this.offer.photos
    },
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    offerPeriod: function () {
      if (this.offer) {
        return {
          from: this.offer.from,
          till: this.offer.till
        }
      }
      return {
        from: null,
        till: null
      }
    },
    invoiceTemplate: function () {
      return `invoice-${this.$store.getters.language}`
    },
    payment_data: function () {
      return {
        type: 'PUBLISH',
        order: this.url,
        redirect: location.toString(),
        locale: this.$store.getters.dateLocale,
        template: this.invoiceTemplate,
        reference: null,
        customer: {
          name: this.profile.EN.name,
          address: this.profile.address,
          postalcode: this.profile.postalcode,
          location: this.profile.location,
          country: this.profile.country,
          email: this.invoiceEmail
        }
      }
    }
  },
  watch: {
    'steps.media': function (newVal) {
      this.showFotoPicker = newVal
    },
    url: {
      immediate: true,
      handler (id) {
        this.$bind('payments', db.doc(this.url).collection('payments'))
      }
    }
  },
  methods: {
    HasLanguage (lang) {
      var b = this.offer[lang]
      if (!b) return false
      if (!b.description || !b.title) return false
      return true
    },
    AddFoto (f) {
      if (!this.offer.photos) this.$set(this.offer, 'photos', [])
      this.offer.photos.push(f)
    },
    OpslaanAdvertentie () {
      db.doc(this.url)
        .set(this.offer)
        .then(() => {
          this.$router.push({ name: 'Advertenties' })
          console.log('opgeslagen')
        })
    },
    focusBlock (title) {
      var keys = Object.keys(this.steps)
      console.log(keys)
      for (var n of keys) this.steps[n] = n === title
    },
    MapThumbs (a) {
      return (a || []).map((f) => {
        return {
          url: cl.url(f.public_id, {
            secure: true,
            width: 350,
            height: 350,
            crop: 'fill'
          }),
          createdOn: f.createdOn,
          public_id: f.public_id,
          id: f.id,
          node: f
        }
      })
    },
    VerwijderAdvertentie () {
      db.doc(this.url)
        .delete()
        .then((r) => {
          this.$router.push({ name: 'Advertenties' })
        })
    },
    PlaatsAdvertentie () {
      var vm = this
      vm.busy = true
      db.doc(this.url)
        .set(this.staged_offer)
        .then(() => {
          var pay = functions.httpsCallable('pay')
          pay(vm.payment_data)
            .then((response) => {
              // ga naar payment site
              console.log(response.data)
              // console.log(response.dta)
              window.location = response.data.paymentUrl
            })
            .catch((error) => {
              console.log(error)
            })
            .finally(() => {
              vm.busy = false
            })
        })
    }
  }
}
</script>
