<template>
  <b-overlay
    :show="isBusy"
    v-if="profile"
  >
    <b-row align-h="center">
      <b-col cols="8">
        <b-card class="my-4">
          {{ $t('tabs.acco_explained') }}
        </b-card>
        <b-form-group
          label-size="lg"
          :label="$t('form.languages')"
          :description="$t('prompts.languages')"
          label-cols="3"
        >
          <b-form-checkbox-group
            size="lg"
            v-model="profile.languages"
            :options="
              languages.map((l) => {
                return { value: l, text: l, disabled: l === 'EN' };
              })
            "
          />
        </b-form-group>
        <b-form-group
          label-size="lg"
          :label="$t('form.description')"
          label-cols="3"
          v-if="profile.languages.length > 0"
        >
          <b-form-group :state="!profile.languages.includes(profileLanguage)">
            <language-tabs
              :languages="
                profile.languages.map((f) => {
                  return { code: f, valid: HasLanguage(f) };
                })
              "
              :selected="profileLanguage"
              @input="profileLanguage = $event"
            />
          </b-form-group>

          <b-alert
            variant="warning"
            :show="!profile.languages.includes(profileLanguage)"
          >
            {{ $t("language.pick") }}
          </b-alert>
          <div v-if="profile.languages.includes(profileLanguage)">
            <b-form-group :label="$t('form.title')">
              <b-form-input v-model="languageBlock.name" />
            </b-form-group>
            <b-form-group :label="$t('form.description')">
              <b-textarea
                v-model="languageBlock.description"
                rows="5"
              />
            </b-form-group>
          </div>
        </b-form-group>
        <b-form-group
          label-size="lg"
          :label="$t('form.stars')"
          label-cols="3"
        >
          <b-rating
            v-model="profile.stars"
            class="text-dark"
            size="lg"
          />
        </b-form-group>
        <b-form-group
          :label="$t('form.address')"
          label-cols="3"
          label-size="lg"
        >
          <b-input
            v-model="profile.address"
            :placeholder="$t('form.street_housenumber')"
            debounce="10000"
          />

          <b-input-group>
            <b-input
              v-model="profile.postalcode"
              :placeholder="$t('form.postal_code')"
              debounce="10000"
            />
            <b-input
              v-model="profile.location"
              :placeholder="$t('form.city')"
              debounce="10000"
            />
          </b-input-group>

          <b-input-group>
            <b-input
              :placeholder="$t('prompts.region_island')"
              v-model="profile.region"
            />
            <countries v-model="profile.country" />
          </b-input-group>
        </b-form-group>

        <b-form-group
          label-size="lg"
          :label="$t('form.location')"
          :description="$t('prompts.location')"
          label-cols="3"
        >
          <geo-coder
            :adres="geoObject"
            :country="profile.country"
            @place_id="pointers.adres = $event"
            v-model="profile.position"
          >
            <google-map :center="profile.position" />
            <b-input-group class="my-3">
              <b-input-group-prepend is-text>
                Latitude
              </b-input-group-prepend>
              <b-input v-model="profile.position.lat" />
              <b-input-group-addon is-text>
                Longitude
              </b-input-group-addon>
              <b-input v-model="profile.position.lng" />
            </b-input-group>
          </geo-coder>
        </b-form-group>
        <!---<b-form-group
          label-size="lg"
          label="PlaceIDs"
          label-cols="3"
        >
          <place-id
            :value="profile.position"
            @place_ids="(v) => (profile.pointers = v)"
          />
        </b-form-group>--->

        <b-form-group
          :label="$t('labels.contact')"
          label-size="lg"
          label-cols="3"
          :description="$t('prompts.contact')"
        >
          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user" />
            </b-input-group-prepend>

            <b-input
              :placeholder="$t('form.hostname')"
              v-model="profile.bookingcontact"
            />
          </b-input-group>

          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="envelope" />
            </b-input-group-prepend>

            <b-input
              type="email"
              :placeholder="$t('form.email')"
              v-model="profile.bookingemail"
            />
          </b-input-group>

          <b-input-group class="mb-2">
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="globe" />
            </b-input-group-prepend>

            <b-input
              type="text"
              :placeholder="$t('form.website')"
              v-model="profile.website"
            />
          </b-input-group>
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="phone" />
            </b-input-group-prepend>
            <b-input
              v-model="profile.phonenumber"
              :placeholder="$t('form.phonenumber')"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          class="my-4"
          label-cols="3"
          :label="$t('labels.tags')"
          :description="$t('prompts.tags')"
          label-size="lg"
        >
          <tag-input v-model="profile.tags" />
        </b-form-group>
        <place-id
          :value="profile.position"
          @place_ids="(v) => {
            (profile.pointers = v)
            Opslaan()
          }"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import Countries from '@/components/Countries'
import { auth, db } from '@/firebase'
import GeoCoder from '@/components/GeoCoder'
import GoogleMap from '../components/GoogleMap.vue'
import { LanguageMixin } from '@/mixins/LanguageMixin'
import { TagMixin } from '@/mixins/TagMixin'
import LanguageTabs from '../components/LanguageTabs.vue'
import PlaceId from '../components/PlaceId.vue'
import TagInput from '../components/TagInput.vue'

export default {
  mixins: [LanguageMixin, TagMixin],
  components: {
    Countries,
    GeoCoder,
    GoogleMap,
    LanguageTabs,
    PlaceId,
    TagInput
  },
  computed: {
    profile_region: function () {
      return this.profile.region || null
    },
    languageBlock: function () {
      // if (!this.profile[this.profileLanguage]) {
      //   this.$set(this.profile, this.profileLanguage, {
      //     description: null,
      //     name: null
      //   })
      // }
      return this.profile[this.profileLanguage] || {
        description: null,
        name: null
      }
    },
    adres: function () {
      return `${this.profile.address}<br/>${this.profile.postalcode} ${this.profile.location} ${this.profile.country}`
    },
    geoObject: function () {
      return {
        address_line_1: this.profile.address,
        address_line_2: '',
        city: this.profile.location,
        country: this.profile.country
      }
    },
    user: function () {
      return auth.currentUser
    },
    userId: function () {
      return this.user.uid
    },
    profile: function () {
      return this.value
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  name: 'Profile',
  data () {
    return {
      profileLanguage: 'EN',
      uploadFile: null,
      concepts: [],
      isBusy: false
    }
  },
  methods: {
    HasLanguage (lang) {
      if (!this.profile[lang]) return false
      if (!this.profile[lang].description || !this.profile[lang].name) {
        return false
      }
      return true
    },
    Opslaan () {
      var vm = this
      vm.isBusy = true
      // this.$set(this.profile, 'pointers', this.ids)
      db.collection('profiles')
        .doc(this.userId)
        .set(this.profile)
        .then((snapshot) => {
          console.log('opgeslagen')
          vm.isBusy = false
        })
    },
    getProfile () {
      var vm = this
      db.collection('profiles')
        .doc(this.userId)
        .get()
        .then((snapshot) => {
          vm.profile = snapshot.data()
          vm.offers = snapshot.data().offers
          // do something with document
        })
    }
  }
}
</script>
