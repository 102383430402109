export const CategoryMixin = {
  methods: {
    GetRateRange (rate) {
      if (rate <= 50) return '50AndLower'
      if (rate <= 100) return 'Between50And100'
      if (rate <= 150) return 'Between100And150'
      if (rate <= 200) return 'Between150And200'
      return '200AndMore'
    },
    GetDiscountRange (discount) {
      if (discount <= 20) return '20AndLower'
      if (discount <= 40) return 'Between20And40'
      return '40AndMore'
    },
    StarOptions () {
      return ['NoStars', '2OrMoreStars', '3OrMoreStars', '4OrMoreStars', '5Stars']
    },
    GetStarsRanges (stars) {
      return {
        NoStars: stars === 0,
        '2OrMoreStars': (stars >= 2),
        '3OrMoreStars': (stars >= 3),
        '4OrMoreStars': (stars >= 4),
        '5Stars': stars === 5
      }
    },
    GetAccoTypes (tags, categories) {
      console.log(tags)
      console.log(categories)
      var types = {}

      categories.map(f => {
        types[f] = tags.includes(f)
      })
      return types
    }

  }
}
