<template>
  <b-card>
    <b-tabs
      vertical
      pills
    >
      <b-tab title="Algemeen">
        <b-form-group title="Cookie">
          <vue-cookie-accept-decline
            :ref="'CookiePanel1'"
            :element-id="'CookiePanel1'"
            position="bottom"
            type="bar"
            :disable-decline="false"
            :transition-name="'slideFromBottom'"
            :show-postpone-button="false"
          />
          <b-button @click="() => $refs.CookiePanel1.removeCookie()">
            <font-awesome-icon icon="cookie" /> Verwijder cookie
          </b-button>
        </b-form-group>
      </b-tab>
      <b-tab title="Facturatie">
        <b-button @click="invoice">
          Test facturatie
        </b-button>
      </b-tab>

      <b-tab title="Landen">
        <key-admin />
      </b-tab>
      <b-tab title="Adverteren">
        <b-form-group title="Soorten advertentie">
          {{ adverts }}
        </b-form-group>
      </b-tab>

      <b-tab
        title="Tag register"
        v-if="settings.tags"
      >
        <b-input-group>
          <b-textarea
            v-model="tag_import"
            placeholder="Quick import multiple tags"
          />
          <b-select
            :options="tagGroups"
            v-model="tag_import_category"
          />
          <b-button @click="importTags">
            <font-awesome-icon icon="import" />
          </b-button>
        </b-input-group>

        <b-table
          :caption="settingsPath"
          small
          bordered
          :items="settings.tags"
          :fields="[
            'tag',
            'active',
            'icon',
            'icon-preview',
            { sortable: true, key: 'category' },
            { sortable: true, key: 'order' },
            {
              sortable: true,
              sortByFormatted: true,
              key: 'sort-order',
              formatter: (value, key, item) => {
                return `${item.category}-${item.order}`;
              },
            },
          ]"
        >
          <template #cell(tag)="data">
            <b-input v-model="data.item.tag" />
          </template>
          <template #cell(icon)="data">
            <b-input v-model="data.item.icon" />
          </template>
          <template #cell(category)="data">
            <b-select
              :options="tagGroups"
              v-model="data.item.category"
            />
          </template>
          <template #cell(sort-order)="data">
            {{ data.value }}
          </template>
          <template #cell(icon-preview)="data">
            <font-awesome-icon :icon="data.item.icon" />
          </template>
          <template #cell(order)="data">
            <b-input v-model.number="data.item.order" />
            <b-button
              @click="
                () => settings.tags.splice(settings.tags.indexOf(data.item), 1)
              "
            >
              verwijder
            </b-button>
          </template>
          <template #cell(active)="data">
            <b-check v-model="data.item.active" />
          </template>

          <template #cell()="data">
            <b-input v-model="data.value" />
          </template>
        </b-table>
        <b-btn-toolbar>
          <b-button
            v-for="i in tagGroups"
            :key="i"
            class="mr-2"
            @click="
              settings.tags.push({
                tag: 'abc',
                category: i,
                icon: '',
                active: true,
                order: 0,
              })
            "
          >
            <font-awesome-icon icon="plus" /> {{ i }}
          </b-button>
        </b-btn-toolbar>
        <b-button
          @click="opslaanSettings"
          variant="success"
          class="my-3"
          size="lg"
        >
          <font-awesome-icon icon="save" />
          Opslaan
        </b-button>

        <b-card
          title="Preview tags input"
          :sub-title="$store.getters.language"
        >
          <b-card-text>
            Het getal achter de omschrijving geeft de volgorde aan voor bij de
            advertentie
          </b-card-text>
          <tag-input
            v-model="previewtags"
            :show-order="true"
          />
        </b-card>

        <b-card
          title="Preview tags output"
          :sub-title="$store.getters.language"
        >
          <tags
            :values="settings.tags"
            :tags="previewtags"
          />
        </b-card>
      </b-tab>

      <b-tab
        title="Vertalingen"
        v-if="basekeys && translations.nl"
      >
        <b-select
          :options="allTags"
          @change="
            $set(basekeys.tags, $event.toLowerCase(), $event.toLowerCase())
          "
          class="my-2"
        />

        <b-button
          v-for="l in languages"
          :key="l"
          @click="save(l)"
          variant="success"
          class="m-2"
        >
          {{ l }} opslaan
        </b-button>

        <b-list-group-item
          v-for="(group, key) in basekeys"
          :key="key"
        >
          <b-card-title>{{ key }}</b-card-title>

          <b-list-group-item
            v-for="(item, node) in group"
            :key="node"
          >
            <b-form-group
              :label="node"
              label-cols="4"
            >
              <b-input-group v-if="!IsNodeObject(key, node)">
                <b-row v-if="key !== 'page'">
                  <b-input
                    v-for="l in languages"
                    :key="l"
                    :debounce="1000"
                    :placeholder="l"
                    v-model="translations[l.toLowerCase()][key][node]"
                  />
                </b-row>
                <b-row v-else>
                  <b-textarea
                    v-for="l in languages"
                    :key="l"
                    :debounce="1000"
                    :placeholder="l"
                    v-model="translations[l.toLowerCase()][key][node]"
                  />
                </b-row>
              </b-input-group>
              <div v-else>
                <b-alert show>
                  Niet via deze pagina te bewerken (zie backend en instructie
                  document)
                </b-alert>
                <b-list-group-item
                  v-for="(c, k) in basekeys[key][node]"
                  :key="k"
                >
                  {{ k }}
                </b-list-group-item>
              </div>
            </b-form-group>
          </b-list-group-item>
        </b-list-group-item>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script>

import KeyAdmin from '@/components/KeyAdmin'
import { db, functions } from '@/firebase'
import { TagMixin } from '@/mixins/TagMixin'
import { LanguageMixin } from '@/mixins/LanguageMixin'
import TagInput from '../components/TagInput.vue'
import Tags from '../components/Tags.vue'

export default {
  mixins: [TagMixin, LanguageMixin],
  name: 'SettingsView',
  components: {
    KeyAdmin,
    TagInput,
    Tags
  },
  data () {
    return {
      adverts: [],
      base: 'en',
      translations: {
        en: null,
        nl: null
      },
      tag_import: null,
      tag_import_category: null,
      settings: null,
      settingsPath: 'settings/default',
      previewtags: []
    }
  },
  firestore: {
    'translations.en': db.doc('i18n/en'),
    'translations.nl': db.doc('i18n/nl'),
    settings: db.doc('settings/default')
  },
  computed: {
    isCountry: function () {
      if (this.place == null) return false
      if (this.place.types.includes('country')) return true
      return false
    },
    import_tags_labels: function () {
      return this.import_tags.map((u, index) => {
        return {
          tag: u.toUpperCase().replaceAll('/', ''),
          category: this.tag_import_category,
          icon: null,
          active: true,
          order: index
        }
      })
    },
    import_tags: function () {
      return ''.concat(this.tag_import).replaceAll(' ', '_').split('\n')
    },
    isRegion: function () {
      if (this.place == null) return false
      if (this.place.types.includes('administrative_area_level_2')) return true
      return false
    },
    basekeys: function () {
      return this.translations[this.base]
    }
  },
  methods: {
    invoice () {
      console.log('test facturatie')
      var invoice = functions.httpsCallable('generateInvoice')
      invoice({ path: '/profiles/TChjWYYGJTTqj4mxxqRaTXnnxnk2/offers/MSuGZFnSLIPklmUVxjFe/payments/tr_pTeKBswP9G' })
    },
    updateTag (language, path, value) {
      console.log(language, path, value)
      //  db.doc(`i18n/${language.toLowerCase()}`).update(path, value.toString())
    },
    IsNodeObject (key, node) {
      var r = this.translations[this.base][key][node]
      return typeof r === 'object'
    },
    save (lang) {
      console.log(lang)
      db.doc(`i18n/${lang.toLowerCase()}`).set(
        this.translations[lang.toLowerCase()]
      )
    },
    opslaanSettings () {
      db.doc(this.settingsPath).set(this.settings)
    },
    importTags () {
      this.import_tags_labels.forEach((i) => this.settings.tags.push(i))
    }
  }
}
</script>
