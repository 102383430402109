
<template>
  <div v-html="articleContent" />
</template>

<script>

import { renderRichText } from '@storyblok/vue-2'
export default {
  name: 'Copy',
  components: {},
  mixins: [],
  props: {
    blok: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    articleContent () {
      return renderRichText(this.blok.body)
    }
  },
  watch: {},
  mounted () {

  },
  methods: {}

}
</script>
