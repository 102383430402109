<template>
  <b-nav
    tabs
  >
    <b-nav-item
      :active="selected === language.code"
      @click="$emit('input',language.code)"
      v-for="language in (languages || [])"
      :key="language.code"
    >
      <font-awesome-icon
        icon="check"
        class="text-success"
        v-if="language.valid"
      /> {{ language.code }}
    </b-nav-item>
  </b-nav>
</template>
<script>
export default {
  props: {
    selected: {
      type: String,
      default: 'NL'
    },
    languages: {
      type: Array,
      default: () => []
    }
  }
}
</script>
