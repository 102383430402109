<template>
  <b-container>
    <b-img
      :src="mainImg"
      class="w-100"
      style="margin-bottom:-18px; margin-top:-10px;"
      v-if="mainImg"
    />

    <Onboarding v-else />

    <section
      class="section-header py-3"
    >
      <!---<b-jumbotron :header="$t('prompts.header_slogan')" />--->
      <router-view
        name="header"
        :settings="settings"
        @onUpdateLocation="updateLocation"
      />
    </section>

    <!-- <marquee-text
      :duration="70"
      class="my-3"
    >
      <span
        v-for="t in $t('tickertape.home').split('++')"
        :key="t"
      >
        {{ t }} <span class="text-dark">+++</span>
      </span>
    </marquee-text> -->

    <keep-alive include="AdGrid">
      <router-view :settings="settings" />
    </keep-alive>
  </b-container>
</template>

<script>
import store from '@/store'
import { TagMixin } from '@/mixins/TagMixin'
import { CategoryMixin } from '../mixins/CategoryMixin'
import { Cloudinary } from 'cloudinary-core'
import { db } from '@/firebase'
import Onboarding from '../components/Onboarding.vue'

const cl = new Cloudinary({ cloud_name: 'booknites' })
export default {
  name: 'Home',
  mixins: [TagMixin, CategoryMixin],
  props: {
    location: {
      type: String,
      default: null
    },
    period: {
      type: String,
      default: null
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      // mainImg: 'https://res.cloudinary.com/booknites/image/upload/v1646121234/assets/skyline-world_xpwbf7.png',
      mainImg: null,
      params: {
        tags: [],
        from: null,
        stars: null,
        till: null,
        acco_type: null,
        starsrange: null,
        discountrange: null,
        pricerange: null,
        placeId: null
      },
      noImgPublicId: 'no-image-available_pecjtz.jpg',
      selectedOffer: null,
      settings: null
    }
  },
  computed: {
    language: function () {
      return store.getters.language
    },
    noImg: function () {
      return this.thumb(this.noImgPublicId)
    }
  },
  firestore: {
    settings: db.doc('settings/default')
  },
  methods: {
    thumb (publicId) {
      return cl.url(publicId, {
        secure: true,
        width: 350,
        height: 200,
        crop: 'fill'
      })
    },
    updateLocation (loc) {
      console.log(loc)
      this.mainImg = loc.image
    },
    large (publicId) {
      return cl.url(publicId, {
        secure: true,
        width: 600,
        height: 400,
        crop: 'fill'
      })
    }
  },
  components: { Onboarding }
}
</script>
