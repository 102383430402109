<template>
  <b-container
    v-if="offer"
  >
    <b-link
      :to="{name: 'Advertenties'}"
    >
      <font-awesome-icon icon="arrow-left" /> {{ $t('navigation.back_to_ads') }}
    </b-link>

    <router-view
      :url="offerUrl"
      :offer="offer"
      class="mt-3"
      @change-language="language = $event"
      :language="language"
      :profile="profile"
      :media="media"
    />
  </b-container>
</template>
<script>
import { auth, db } from '@/firebase'
import { Cloudinary } from 'cloudinary-core'
const cl = new Cloudinary({ cloud_name: 'booknites' })

export default {
  name: 'Offer',
  data () {
    return {
      offer: null,
      language: 'EN',
      noimg: 'no-image-available_pecjtz.jpg'
    }
  },
  computed: {
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    offerThumb: function () {
      if (!this.offer.photos) {
        return cl.url(this.noimg, {
          secure: true,
          width: 350,
          height: 350,
          crop: 'fill'
        })
      }
      if (this.offer.photos.length === 0) {
        return cl.url(this.noimg, {
          secure: true,
          width: 350,
          height: 350,
          crop: 'fill'
        })
      }
      return cl.url(this.offer.photos[0].public_id, {
        secure: true,
        width: 350,
        height: 350,
        crop: 'fill'
      })
    },
    offerUrl: function () {
      return `profiles/${auth.currentUser.uid}/offers/${this.id}`
    }
  },
  methods: {

  },
  watch: {
    userId: {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('offer', db.doc(this.offerUrl))
      }
    }
  },
  props: {
    id: {
      type: String,
      default: null
    },
    profile: {
      type: Object,
      default: null
    },
    media: {
      type: Array,
      default: () => []
    }
  }
}
</script>
