import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import DetailOffer from '../views/DetailOffer.vue'
import Settings from '../views/Settings.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Profile from '../views/Profile'
import ProfileOffers from '../views/ProfileOffers'
import ProfileFotos from '../views/ProfileFotos'
import ProfileEdit from '../views/ProfileEdit'
import OfferList from '../views/OfferList'
import PaymentsList from '../views/Payments'
import Offer from '../views/Offer'
import OfferEdit from '../views/OfferEdit'
import OfferStatus from '../views/OfferStatus'
import SearchOffers from '@/components/SearchOffers'
import AdGrid from '@/views/AdGrid'
import store from '../store'
import firebase from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    // beforeEnter: (to, from, next) => {
    //   if (store.state.skipWelcome === false) {
    //     next('/welcome')
    //   }
    //   next()
    // },
    component: () => import('../layouts/Default.vue'),
    children: [

      {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../views/Contact.vue')
      },
      {
        path: '/general_conditions',
        props: { subject: 'page.general_conditions' },
        name: 'Terms',
        component: () => import('../views/Page.vue')
      },
      {
        name: 'AdminSettings',
        path: '/admin/settings',
        component: Settings
      },
      {
        name: 'PaymentsList',
        path: '/admin/payments',
        component: PaymentsList
      },
      {
        path: '/register',
        name: 'Register',
        component: Register
      },
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/',
        name: 'Home',
        component: Home,
        children: [
          {
            name: 'DetailOffer',
            path: 'offer/:offerid',
            props: {
              default: true
            },
            components: {
              default: DetailOffer,
              header: SearchOffers
            }
          },
          {
            path: 'period/:period',
            name: 'ZoekenPeriode',
            props: {
              default: route => ({ period: route.params.period })
            },
            components: {
              default: AdGrid,
              header: SearchOffers
            }
          },
          {
            path: 'location/:location',
            name: 'ZoekenLocatie',
            props: {
              default: route => ({ location: route.params.location })
            },
            components: {
              default: AdGrid,
              header: SearchOffers
            }
          },
          {
            path: 'period/:period/location/:location',
            name: 'ZoekenPeriodeLocatie',
            props: {
              default: route => ({ location: route.params.location, period: route.params.period })
            },
            components: {
              default: AdGrid,
              header: SearchOffers
            }
          },
          {
            path: ':c?',
            name: 'HomeDefault',
            props: {
              default: route => ({ cursor: route.params.c })
            },
            components: {
              default: AdGrid,
              header: SearchOffers
            }
          }
        ]
      },

      {
        path: '/profile',
        name: 'Profile',
        redirect: 'acco',
        component: Profile,
        meta: {
          requiresAuth: true
        },
        children: [
          {
            path: 'acco',
            name: 'Accomodatie',
            component: ProfileEdit,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'fotos',
            name: 'Fotos',
            component: ProfileFotos,
            meta: {
              requiresAuth: true
            }
          },
          {
            path: 'advertenties',
            name: 'Advertenties',
            component: ProfileOffers,
            redirect: 'advertenties/list',
            meta: {
              requiresAuth: true
            },
            children: [
              {
                path: 'list',
                name: 'AdvertentiesLijst',
                component: OfferList,
                meta: {
                  requiresAuth: true
                }
              },
              {
                path: ':id',
                name: 'Advertentie',
                component: Offer,
                props: true,
                redirect: ':id/edit',
                meta: {
                  requiresAuth: true
                },
                children: [
                  {
                    path: 'edit',
                    name: 'AdvertentieEdit',
                    component: OfferEdit,
                    meta: {
                      requiresAuth: true
                    }
                  }, {
                    path: 'status',
                    name: 'AdvertentieStatus',
                    component: OfferStatus,
                    meta: {
                      requiresAuth: true
                    }
                  }]
              }
            ]
          }
        ]
      }
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.user.loggedIn) {
      next('/login')
    } else {
      next()
    }
  })
})

export default router
