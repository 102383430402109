<template>
  <b-row>
    <b-col>
      <b-card class="my-4">
        {{ $t('tabs.ads_explained') }}<br><br>
        <strong>{{ $t('prompts.publish_ad') }}</strong>
      </b-card>
      <b-button
        class="my-3"
        variant="primary"
        @click="createConcept"
      >
        <font-awesome-icon icon="plus" />  {{ $t('buttons.new_ad') }}
      </b-button>
      <b-table
        :fields="fields"
        :items="concepts"
        caption-top
      >
        <template v-slot:cell(DESC)="row">
          <div v-if="!row.item.EN">
            {{ $t('prompts.no_title_input') }}
          </div>
          <div v-else>
            <h6>{{ row.item.EN.title }} <small v-if="row.item.reference">({{ row.item.reference }})</small></h6>
          </div>
        </template>
        <template
          v-slot:cell(STATUS)="row"
        >
          <font-awesome-icon
            :title="$t(`labels.${row.item.status.toLowerCase()}`)"
            icon="history"
            v-if="row.item.status == 'EXPIRED'"
          />

          <font-awesome-icon
            :title="$t(`labels.${row.item.status.toLowerCase()}`)"
            icon="play"
            v-if="row.item.status == 'PUBLISH'"
          />
          <b-button
            @click="stopAdPrompt = row.item"
            v-if="row.item.status == 'PUBLISH'"
            variant="danger"
            size="sm"
            class="ml-3"
          >
            <font-awesome-icon icon="stop" />
            {{ $t('buttons.stop') }}
          </b-button>
          <font-awesome-icon
            :title="$t(`labels.${row.item.status.toLowerCase()}`)"
            icon="stop"
            v-if="row.item.status == 'CANCELLED'"
          />
        </template>

        <template v-slot:cell(ACTIONS)="row">
          <b-button-group size="sm">
            <b-button
              @click="replaceAdPrompt = {...row.item,from: null, till: null}"
              variant="secondary"
            >
              <font-awesome-icon icon="redo" />
              {{ $t('buttons.republish') }}
            </b-button>

            <b-button
              @click="$router.push({name:'Advertentie',params: {id:row.item.id}})"
              v-if="row.item.status == 'CONCEPT'"
              variant="primary"
            >
              <font-awesome-icon icon="edit" />
              {{ $t('buttons.edit') }}
            </b-button>
          </b-button-group>
        </template>
        <template v-slot:cell(PR)="row">
          {{ $moment(row.item.from).format('LL') }} - {{ $moment(row.item.till).format('LL') }}<br>
          {{ $tc('labels.days', $moment(row.item.till).diff(row.item.from, 'days') ) }}
        </template>
        <template v-slot:cell(RATE)="row">
          <h4>€ {{ row.item.rate }} <b-badge>-{{ row.item.discount }}%</b-badge></h4>
        </template>
      </b-table>
      <b-modal
        :visible="stopAdPrompt !== null"
        :title="$t('buttons.stop')"
        @ok="stopAd(stopAdPrompt.id)"
        @hide="stopAdPrompt = null"
      >
        {{ $t('prompts.stop') }}
      </b-modal>
      <b-modal
        :visible="replaceAdPrompt !== null"
        :title="$t('buttons.replace')"
        @ok="replaceAd(replaceAdPrompt)"
        :ok-disabled="replacePeriodComplete"
        @hide="replaceAdPrompt = null"
      >
        {{ $t('prompts.replace') }}
        <tarif-period
          :offer="replaceAdPrompt"
          :services="services"
        />
      </b-modal>
    </b-col>
  </b-row>
</template>
<script>
import { auth, db, functions } from '@/firebase'
import { LanguageMixin } from '@/mixins/LanguageMixin'
import TarifPeriod from '../components/TarifPeriod.vue'

export default {
  mixins: [LanguageMixin],
  components: {
    TarifPeriod
  },
  computed: {
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    replacePeriodComplete: function () {
      if (this.replaceAdPrompt == null) return true
      if (this.replaceAdPrompt.from == null || this.replaceAdPrompt.till == null) return true
      return false
    }
  },
  watch: {
    userId: {
      immediate: true,
      handler (id) {
        this.$bind('concepts', db.collection('profiles').doc(this.userId).collection('offers').where('status', '!=', 'CONCEPT'))
      }
    }
  },
  firestore: {
    services: db.collection('services').where('active', '==', true)
  },
  name: 'OfferList',
  data () {
    return {
      services: [],
      currentLanguage: 'EN',
      concepts: [],
      stopAdPrompt: null,
      replaceAdPrompt: null,
      replaceDates: {
        from: null,
        till: null
      },
      isBusy: false,
      fields: [
        { key: 'DESC', label: this.$t('table.description') },
        { key: 'PR', label: this.$t('table.period') },
        { key: 'RATE', label: this.$t('table.rate') },
        { key: 'STATUS', label: this.$t('table.status'), tdClass: 'text-center' },
        { key: 'ACTIONS', label: this.$t('table.actions') }
      ]
    }
  },
  props: {
    profile: {
      type: Object,
      default: () => {}
    },
    language: {
      type: String,
      default: 'EN'
    }
  },
  methods: {
    stopAd (itemId) {
      // Get Cloud function named Upload
      var cancel = functions.httpsCallable('userCancelsAd')
      cancel(itemId)
        .then(response => {
          console.log(response.data)
        })
        .catch((error) => {
        // Return any error message
          console.log(error)
        })
    },
    replaceAd (ad) {
      var vm = this
      db.collection('profiles').doc(this.userId).collection('offers').add({ ...ad, status: 'CONCEPT' })
        .then((d) => vm.$router.push({ name: 'Advertentie', params: { id: d.id } }))
    },
    createConcept () {
      var vm = this
      db.collection('profiles').doc(this.userId).collection('offers').add({
        from: null,
        till: null,
        rate: null,
        normalrate: null,
        status: 'CONCEPT',
        discount: 10
      }).then((d) => vm.$router.push({ name: 'Advertentie', params: { id: d.id } }))
    }
  }
}
</script>
