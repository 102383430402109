<template>
  <b-carousel
    v-if="story.value"
    id="carousel-1"
    :interval="parseInt(story.value.content.interval)"
    background="#ababab"
    img-width="1024"
    img-height="500"
    style="text-shadow: 1px 1px 2px #333;"
  >
    <!-- Text slides with image -->
    <b-carousel-slide
      rounded="top"
      v-for="(s,index) in story.value.content.slides"
      content-visible-up="sm"
      :key="index"
      :caption="s.titel"
      :text="s.tagline"
      :img-src="`${s.afbeelding.filename}/m/1024x${story.value.content.height}`"
    />
  </b-carousel>
</template>
<script lang="ts">
import { useStoryblok } from '@storyblok/vue-2'
export default {
  name: 'Onboarding',
  components: {},
  mixins: [],
  props: {
  },
  data () {
    return {
    }
  },
  computed: {
    story: function () {
      return useStoryblok('onboarding', { version: 'draft' })
    }
  },
  watch: {},
  mounted () {

  },
  methods: {}

}
</script>
