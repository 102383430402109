<template>
  <b-container>
    <b-form-group :label="$t('labels.choose_max_duration')">
      <b-select
        :options="serviceoptions"
        v-model="maxdays"
        @change="(s) => $emit('service-selected',s)"
      />
    </b-form-group>

    <b-form-group :label="$t('form.period')">
      <period
        :value="offerPeriod"
        @update-period="setNewPeriod"
        :vanlabel="$t('form.from')"
        :totlabel="$t('form.till')"
        :maxdays="maxdays"
      />
    </b-form-group>

    <b-form-group
      label="Normaal tarief"
      prepend="EURO"
      :description="$t('labels.pn')"
    >
      <b-input-group prepend="EUR">
        <b-input
          type="number"
          step="1"
          v-model.number="offer.normalrate"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Kortingspercentage"
      append="%"
      description="percentage korting ten opzichte van het normale tarief"
    >
      <b-input-group :append="`${offer.discount}%`">
        <b-input
          type="range"
          v-model.number="offer.discount"
          max="90"
          step="5"
          min="10"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group
      label="Actie tarief"
      :description="$t('labels.pn')"
      prepend="EURO"
    >
      <b-input-group prepend="EUR">
        <b-input
          v-model.number="offer.rate"
          step="1"
          type="number"
          readonly
        />
      </b-input-group>
    </b-form-group>
  </b-container>
</template>
<script>
import { FormattingMixin } from '../mixins/FormattingMixin'
import Period from './Period.vue'
export default {
  components: { Period },
  mixins: [FormattingMixin],
  name: 'TarifPeriod',
  props: {
    offer: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    },
    services: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    'offer.normalrate': function (newval) {
      // discount misschien anders?
      // this.offer.discount = (newval / this.offer.normalrate) * 100
      this.offer.rate = Number(
        Math.round((newval / 100) * (100 - this.offer.discount))
      )
    },
    'offer.discount': function (newval) {
      // reken rate uit
      this.offer.rate = Number(
        Math.round((this.offer.normalrate / 100) * (100 - newval))
      )
    },
    'offer.rate': function (newval) {
      // aanpassen rate geeft een ander kortingspercentage
      // this.offer.discount = (newval / this.offer.normalrate) * 100
    }
  },
  computed: {
    normalrate: function () {
      return Math.round((this.offer.rate / (100 - this.offer.discount)) * 100)
    },
    offerPeriod: function () {
      if (this.offer) {
        return {
          from: this.offer.from,
          till: this.offer.till
        }
      }
      return {
        from: null,
        till: null
      }
    },
    serviceoptions: function () {
      return this.services.map((o) => {
        return {
          value: o.maxdays,
          text: this.$t(o.i18n_label, { price: this.eur(o.price, 'nl') })
        }
      })
    }
  },
  data () {
    return {
      maxdays: 15
    }
  },
  methods: {
    setNewPeriod (period) {
      console.log(period)
      this.$set(this.offer, 'from', period.from)
      this.$set(this.offer, 'till', period.till)
    }
  }
}
</script>
