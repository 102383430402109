<template>
  <b-form>
    <b-card>
      <b-card-title>
        {{ eur(rate, language) }} {{ $t('labels.pn') }}
      </b-card-title>
      {{ rate_description }}
    </b-card>
    <b-form-group :label="$t('form.booknow_name')">
      <b-input-group>
        <b-input-group-prepend>
          <b-select
            required
            :state="validation.title"
            @change="validation.title = null"
            :options="$t('form.booknow_titles')"
            v-model="booker.title"
          />
        </b-input-group-prepend>
        <b-input
          v-model="booker.firstname"
          :state="validation.firstname"
          @change="validation.firstname = null"
          :placeholder="$t('form.booknow_firstname')"
        />
        <b-input
          v-model="booker.lastname"
          :state="validation.lastname"
          @change="validation.lastname = null"
          :placeholder="$t('form.booknow_lastname')"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group :label="$t('form.booknow_contact')">
      <b-input-group>
        <b-input
          v-model="booker.email"
          :state="validation.email"
          @change="validation.email = null"
          :placeholder="$t('form.booknow_mail')"
        />
        <b-input
          v-model="booker.phone"
          :state="validation.phone"
          @change="validation.phone = null"
          :placeholder="$t('form.booknow_phone')"
        />
      </b-input-group>
    </b-form-group>
    <b-form-row>
      <b-col>
        <b-form-group :label="$t('form.booknow_rooms')">
          <b-input v-model="booker.rooms" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group :label="$t('form.booknow_persons')">
          <b-input v-model="booker.persons" />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-group
      :label="$t('form.booknow_period')"
    >
      <div class="datepicker-trigger">
        <b-input
          type="text"
          id="datepicker-trigger"
          placeholder="Select dates"
          :value="period_description"
          :state="validation.period"
        />
        <AirbnbStyleDatepicker
          mode="range"
          :trigger-element-id="'datepicker-trigger'"
          :fullscreen-mobile="true"
          :date-one="booker.period.from"
          :date-two="booker.period.till"
          :min-date="min"
          :end-date="max"
          @date-one-selected="val => { booker.period.from = val; validation.period = null; }"
          @date-two-selected="val => { booker.period.till = val; validation.period = null; }"
          :show-shortcuts-menu-trigger="false"
        />
      </div>
    </b-form-group>
    <b-form-group :label="$t('form.booknow_message')">
      <b-textarea v-model="booker.message" />
    </b-form-group>
  </b-form>
</template>
<script>
import { FormattingMixin } from '../mixins/FormattingMixin'
export default {
  mixins: [FormattingMixin],
  name: 'BookingForm',
  computed: {
    dateLocale: function () {
      return this.$store.getters.dateLocale
    },
    period_description: function () {
      var m = this.$moment
      return this.$t('prompts.period_description', {
        from: m(this.booker.period.from).isValid()
          ? m(this.booker.period.from)
            .locale(this.dateLocale)
            .format('L')
          : '-',
        till: m(this.booker.period.till).isValid()
          ? m(this.booker.period.till)
            .locale(this.dateLocale)
            .format('L')
          : '-'
      })
    },
    rate_description: function () {
      var m = this.$moment
      return this.$t('form.booknow_valid', {
        from: m(this.min).locale(this.dateLocale).format('L'),
        till: m(this.max).locale(this.dateLocale).format('L')
      })
    },
    language: function () {
      return this.$store.getters.language
    }
  },
  props: {
    booker: {
      type: Object,
      default: null
    },
    validation: {
      type: Object,
      default: null
    },
    min: {
      type: Date,
      default: null
    },
    max: {
      type: Date,
      default: null
    },
    rate: {
      type: Number,
      default: null
    }
  }
}
</script>
