<template>
  <b-card-body v-if="acco ">
    <b-card-title>
      {{ acco[language].name }}
    </b-card-title>
    <b-card-text>
      {{ acco[language].description }}
    </b-card-text>
    <b-card-title class="h5">
      {{ $t('labels.acco_details') }}
    </b-card-title>
    <b-card-text>
      {{ acco.address }}<br>
      {{ acco.postalcode }} {{ acco.location }} {{ acco.country }}<br>
      <font-awesome-icon icon="phone" />  {{ acco.phonenumber }}<br>
      <font-awesome-icon icon="envelope" /> <b-link
        target="_blank"
        :href="`mailto:${acco.bookingemail}`"
      >
        {{ acco.bookingemail }}
      </b-link><br>

      <font-awesome-icon icon="globe" /><b-link
        target="_blank"
        v-if="acco.website"
        :href="acco.website"
      >
        {{ acco.website }}
      </b-link>
    </b-card-text>

    <b-img
      v-if="acco"
      width="200"
      height="200"
      :src="acco.img.secure_url"
    />
  </b-card-body>
</template>
<script>
import { db } from '@/firebase'

export default {
  name: 'AccoInfo',
  components: { },
  mixins: [],
  props: {
    id: {
      type: String,
      default: '-'

    },
    language: {
      type: String,
      default: 'EN'
    }
  },
  data () {
    return {
      acco: null
    }
  },
  computed: {},
  watch: {
    id: {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('acco', db.collection('profiles').doc(id))
      }
    }
  },
  mounted () {

  },
  methods: {

  }

}
</script>
