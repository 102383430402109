<template>
  <div>
    <router-view />

    <!---
      <b-button @click="() => $refs.CookiePanel1.removeCookie()">
        Verwijder cookie
      </b-button>
    --->

    <vue-cookie-accept-decline
      :ref="'CookiePanel1'"
      :element-id="'CookiePanel1'"
      position="bottom"
      type="bar"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      :show-postpone-button="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website.
        <a
          href="https://cookiesandyou.com/"
          target="_blank"
        >Learn More...</a>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        GOT IT!
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>
<script>
import store from './store'
import { bootstrap } from 'vue-gtag'
export default {
  components: {

  },
  data () {
    return {
      showSidebar: true,
      items: [
        { name: 'HomeDefault', label: 'navigation.home' },
        { name: 'About', label: 'navigation.about' },
        { name: 'Contact', label: 'navigation.contact' }
        // { name: 'Blog', label: 'navigation.blog' }
      ]
    }
  },
  methods: {
    cookieStatus (e) {
      console.log(e)
      if (e === 'accept') {
        bootstrap().then((gtag) => {
          console.log('tracking started')
          // console.dir(gtag)
        })
      }
      store.dispatch('setCookiePermission', e)
    },
    cookieClickedAccept () {
      console.log('accept')
      store.dispatch('setCookiePermission', 'accept')
    },
    cookieClickedDecline () {
      console.log('decline')
      store.dispatch('setCookiePermission', 'decline')
    }
  }
}
</script>
