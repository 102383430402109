import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import { StoryblokVue, apiPlugin } from '@storyblok/vue-2'
import './plugins/fontawesome'
import './plugins/bootstrap-vue'
import App from './App.vue'
import Copy from './components/Copy.vue'
import LoginForm from './components/LoginForm.vue'
import Register from './views/Register.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { firestorePlugin } from 'vuefire'
import Cloudinary from 'cloudinary-vue'
import VueI18n from 'vue-i18n'
import VueSelectImage from 'vue-select-image'
import { db } from '@/firebase'
import './custom.scss'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSocialSharing from 'vue-social-sharing'
import AirbnbStyleDatepicker from 'vue-airbnb-style-datepicker'
import 'vue-airbnb-style-datepicker/dist/vue-airbnb-style-datepicker.min.css'
import MarqueeText from 'vue-marquee-text-component'

import VueSimpleMarkdown from 'vue-simple-markdown'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

import VueCookieAcceptDeclineUmd from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import VueGtag from 'vue-gtag'

store.subscribe((mutation, state) => {
  // Store the state object as a JSON string
  // console.log('mutation')
  // console.dir(state)
  // localStorage.setItem('store', JSON.stringify(state))
})

Vue.use(VueSimpleMarkdown)
Vue.use(VueI18n)
Vue.use(VueSelectImage)
Vue.use(VueSocialSharing)
Vue.use(VueGtag, {
  config: { id: 'G-SB46ZH8MP4' },
  pageTrackerScreenviewEnabled: true,
  params: {
    // send_page_view: false,
    bootstrap: false
  }
}, router)
// see docs for available options
const datepickerOptions = {}

// make sure we can use it in our components
Vue.use(AirbnbStyleDatepicker, datepickerOptions)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyACFqeIBU81ElQmpgI8_G7-HxuxMwl1w9U',
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    // v: '3.26',
  }
  // autobindAllEvents: false,
  // installComponents: true
})
Vue.use(Cloudinary, {
  configuration: { cloudName: 'booknites' }
})

Vue.use(StoryblokVue, {
  accessToken: 'GpdoizecUQ7SIktJulYE2Att',
  apiOptions: {
    cache: { type: 'memory' }
  },
  use: [apiPlugin]
})

Vue.use(firestorePlugin)
const moment = require('moment')
require('moment/locale/nl')
require('moment/locale/en-gb')

Vue.use(require('vue-moment'), {
  moment
})

Vue.component('MarqueeText', MarqueeText)
Vue.component('Register', Register)
Vue.component('VueCookieAcceptDecline', VueCookieAcceptDeclineUmd)
Vue.component('Copy', Copy)
Vue.component('LoginForm', LoginForm)

// add stylesheet
require('vue-select-image/dist/vue-select-image.css')

var messages = {}

var lang = 'en'
if (localStorage.getItem('language')) {
  lang = localStorage.getItem('language').toLowerCase()
  console.log('opgeslagen taal', lang)
}

db.doc(`i18n/${lang}`).get().then(response => {
  i18n.setLocaleMessage(lang, response.data())
  console.log(response.data())
})

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  render: h => h(App)
}).$mount('#app')

document.querySelectorAll('#yourElement a').forEach(a => {
  a.addEventListener('click', (e) => {
    e.preventDefault()
    this.$router.push({ path: a.attributes.href.value })
  })
})
