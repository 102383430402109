<template>
  <b-overlay
    :show="isBusy"
  >
    <b-container>
      <b-row
        class="mb-4"
        v-if="profile"
      >
        <b-col>
          <cld-image
            v-if="profile.img"
            class="float-left mr-3"
            :public-id="profile.img.public_id"
            width="150"
            height="150"
            crop="fill"
          >
            <cld-placeholder
              type="pixelate"
            />
          </cld-image>
          <img
            v-else
            class="float-left mr-3"
            :src="noImg"
            width="150"
          >
          <h3>
            {{ title }} <small> ({{ currentLanguage }})  <font-awesome-icon
              icon="star"
              v-for="n in profile.stars"
              class="text-dark"
              :key="n"
            /></small>
          </h3>
          <p class="small">
            {{ profile.address }}<br>
            {{ profile.postalcode }} {{ profile.location }} {{ profile.country }}<br>
            {{ profile.phonenumber }}<br>
            {{ profile.bookingemail }}<br>
            <b-link
              target="_blank"
              v-if="profile.website"
              :href="profile.website"
            >
              {{ profile.website }}
            </b-link>
          </p>
        </b-col>
      </b-row>
      <b-overlay
        v-else
        show
      >
        <b-card>
          Profiel nog niet gevonden <small>{{ userId }}</small>!
        </b-card>
      </b-overlay>
      <b-nav
        tabs
        class="mb-4"
      >
        <b-nav-item
          :active="tabRoute == 'Accomodatie'"
          :to="{name:'Accomodatie'}"
        >
          {{ $t('tabs.acco') }}
        </b-nav-item>
        <b-nav-item
          :active="tabRoute == 'Fotos'"
          :to="{name:'Fotos'}"
        >
          {{ $t('tabs.photos') }} <b-badge pill>
            {{ media.length }} {{ $tc('form.photos', media.length) }}
          </b-badge>
        </b-nav-item>
        <b-nav-item
          :active="tabRoute == 'Advertenties'"
          :to="{name:'Advertenties'}"
        >
          {{ $t('tabs.ads') }}
        </b-nav-item>
      </b-nav>
    </b-container>
    <b-container v-if="user.emailVerified">
      <router-view
        v-if="profile"
        v-model="profile"
        :profile="profile"
        :media="media"
        :concepts="concepts"
      />

      <b-overlay
        v-else
        show
      >
        <b-card>
          Profiel nog niet gevonden <small>{{ userId }}</small>!
        </b-card>
      </b-overlay>
    </b-container>
    <b-container
      v-else
      class="text-center"
    >
      <b-alert
        variant="info"
        show
      >
        {{ $t('prompts.email_not_verified') }}
      </b-alert><br><br>
      <b-button
        @click="SendVerification"
        variant="primary"
      >
        {{ $t('buttons.send_email_verification_link') }}
      </b-button>
    </b-container>
  </b-overlay>
</template>
<script>
import { auth, db } from '@/firebase'
import { LanguageMixin } from '@/mixins/LanguageMixin'

export default {
  mixins: [LanguageMixin],
  components: {},
  computed: {
    crumbs: function () {
      return this.$route.matched.map((r) => {
        return {
          text: r.name,
          to: r.path
        }
      })
    },
    adres: function () {
      return `${this.profile.address}<br/>${this.profile.postalcode} ${this.profile.location} ${this.profile.country}`
    },
    title: function () {
      return this.profile[this.currentLanguage]
        ? this.profile[this.currentLanguage].name
        : 'NO [NAME] IN ' + this.currentLanguage
    },
    userId: function () {
      const user = auth.currentUser
      return user.uid
    },
    user: function () {
      return auth.currentUser
    },
    tabRoute: function () {
      return this.$route.matched[2].name
    }
  },
  watch: {
    userId: {
      // call it upon creation too
      immediate: true,
      handler (id) {
        this.$bind('profile', db.collection('profiles').doc(this.userId))
        this.$bind(
          'concepts',
          db.collection('profiles').doc(this.userId).collection('offers')
        )
        this.$bind(
          'media',
          db
            .collection('profiles')
            .doc(this.userId)
            .collection('media')
            .orderBy('created_at', 'desc')
        )
      }
    }
  },
  name: 'Profile',
  data () {
    return {
      noImg: './img/no-image-available.jpg',
      currentLanguage: 'EN',
      profile: null,
      profileImage: null,
      uploadFile: null,
      concepts: [],
      media: [],
      isBusy: false
    }
  },
  methods: {
    SendVerification () {
      this.user.sendEmailVerification({ url: 'https://book-nites-dev.web.app/#/profile/acco' })
    },
    Opslaan () {
      var vm = this
      vm.isBusy = true
      db.collection('profiles')
        .doc(this.userId)
        .set(this.profile)
        .then((snapshot) => {
          // console.log('opgeslagen')
          vm.isBusy = false
        })
    },
    getProfile () {
      var vm = this
      db.collection('profiles')
        .doc(this.userId)
        .get()
        .then((snapshot) => {
          vm.profile = snapshot.data()
          vm.offers = snapshot.data().offers
          // do something with document
        })
    }
  }
}
</script>
