import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab, faTwitter)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
