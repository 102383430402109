<template>
  <b-modal
    title="Betaling verifieren"
    v-model="modalvisible"
  >
    <b-overlay>
      STATUS
    </b-overlay>
  </b-modal>
</template>
<script>
export default {
  name: 'OfferStatus',
  data () {
    return {
      modalvisible: true
    }
  }

}
</script>
