<template>
  <b-container>
    <b-row>
      <b-col>
        <b-form-group :label="$t('labels.destination')">
          <vue-google-autocomplete
            id="map"
            classname="form-control"
            types="(regions)"
            :fields="[
              'address_components',
              'adr_address',
              'alt_id',
              'formatted_address',
              'geometry',
              'icon',
              'id',
              'name',
              'business_status',
              'photo',
              'place_id',
              'scope',
              'type',
              'url',
              'utc_offset_minutes',
              'vicinity',
            ]"
            :placeholder="$t('prompts.search_bar')"
            @placechanged="getAddressData"
          >
            <input>
          </vue-google-autocomplete>
        </b-form-group>
      </b-col>
      <b-col>
        <b-card v-if="isCountry">
          <b-button
            @click="saveKey(place.place_id, keyFormat(place, 'COUNTRY'))"
          >
            <font-awesome-icon icon="plus" /> {{ place.name }} toevoegen ({{
              place.address_components[0].short_name
            }})
          </b-button>
        </b-card>
        <b-card v-else-if="isRegion">
          <b-button
            @click="saveKey(place.place_id, keyFormat(place, 'REGION'))"
          >
            <font-awesome-icon icon="plus" /> {{ place.name }} streek toevoegen
            ({{ place.address_components[0].short_name }})
          </b-button>
        </b-card>
        <b-card v-else-if="place !== null">
          <b-button @click="saveKey(place.place_id, keyFormat(place, 'CITY'))">
            <font-awesome-icon icon="plus" /> {{ place.name }} stad toevoegen
            ({{ place.address_components[0].short_name }})
          </b-button>
        </b-card>

        <pre>
            Resultaat:
            {{ place }}
          </pre>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import { db } from '@/firebase'

export default {
  name: 'KeyAdmin',
  components: {
    VueGoogleAutocomplete
  },
  data () {
    return {
      place: null,
      country: null
    }
  },
  methods: {
    getAddressData (addressData, placeResultData, id) {
      console.log('getAddressData')
      console.log(addressData)
      console.log(placeResultData)
      this.place = placeResultData
    },
    saveKey (key, data) {
      db.doc('/settings/default/keys/' + key, data).set(data)
    },
    keyFormat (place, type) {
      return {
        type: type,
        value: place.place_id,
        text: place.name,
        country: this.country ? this.country.value : null,
        code: place.address_components[0].short_name
      }
    }
  }
}
</script>
